import _ from 'lodash';
import {BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {ErrorResponse} from '~/lib/api/types/errors/ErrorResponse';
import {FetchPage} from '~/lib/api/types/FetchPage';
import {PageResponse} from '~/lib/api/types/PageResponse';
import {Transaction} from '~/lib/api/types/Transaction';
import {TransactionsAggregate} from '~/lib/api/types/TransactionsAggregate';
import {mapStatusLabel} from '~/lib/types/TransactionView';
import {assertNotifyOnly} from '~/lib/util/util';


export default class TransactionsApiClient extends BaseApiClient {
  async getTransactions(account: Account | null, fetchPage: FetchPage) {
    let resp: Transaction[] | ErrorResponse = await this.$get<Transaction[]>('/transactions/')

    if(resp instanceof ErrorResponse) {
      throw resp.toError();
    }

    const withPage = (page: number) => {
      const fp = _.cloneDeep(fetchPage);
      fp.page = page;
      return fp;
    };

    const prev: Transaction[] = fetchPage.page === 1 ? [] : this._fakeSortAndPaginate(account, withPage(fetchPage.page - 1), resp);
    const current: Transaction[] = this._fakeSortAndPaginate(account, fetchPage, resp);
    const next: Transaction[] = this._fakeSortAndPaginate(account, withPage(fetchPage.page + 1), resp);

    const pageResponse: PageResponse<Transaction> = {
      count: current.length,
      results: current,
      next: next.length > 0 ? 'simulate_next' : null,
      previous: prev.length > 0 ? 'simulate_prev' : null,
    };

    return pageResponse;
  }

  getTransactionsAggregate() {
    return this.$get<TransactionsAggregate>('/transactions/aggregate/')
  }

  private _fakeSortAndPaginate(account: Account | null, fetchPage: FetchPage, transactions: Transaction[]) {

    // sort by
    if(fetchPage.sort_by) {
      const sort = (field: string | ((t: Transaction) => string | number)) => _.orderBy(transactions, field, fetchPage.sort_by!.direction);

      assertNotifyOnly(account, 'account must be set for transactions sorting');

      switch (fetchPage.sort_by.field) {
        case 'child_money':
          transactions = sort(t => Number(t.child_money.replace(' zł', '')));
          break;
        case 'status':
          transactions = sort(t => account ? mapStatusLabel(account, t) : t.status);
          break;
        default:
          transactions = sort(fetchPage.sort_by.field);
      }
    }

    // paginate

    // fetchPages start with page 1
    const page                = fetchPage.page - 1;
    const from                = page * fetchPage.page_size;
    return transactions.slice(from).slice(0, fetchPage.page_size);
  }
}
