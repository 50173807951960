// import * as faker from 'faker';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {transactionsApiClient} from '~/lib/accessors/api';
import {emptyFetchPage, FetchPage, SortBy} from '~/lib/api/types/FetchPage';
import {defaultSortDirection, SortDirection} from '~/lib/api/types/Field';
import {Transaction} from '~/lib/api/types/Transaction';
import {emptyTransactionsAggregate, TransactionsAggregate} from '~/lib/api/types/TransactionsAggregate';
import appConfig from '~/lib/util/appConfig';
// import {store} from "~/store/index";

@Module({
  name: 'TransactionsStore',
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class TransactionsStore extends VuexModule implements IBaseStore {
  transactions: Transaction[]                  = []
  transactionsAggregate: TransactionsAggregate = emptyTransactionsAggregate()
  fetchPage: FetchPage                         = emptyFetchPage()
  account: Account | null                 = null
  defaultSortField: keyof Transaction          = 'dt'
  hasNextPage: boolean                         = true


  @Action
  initializeTransactionsStore({account, fetchPage}: {account: Account | undefined | null, fetchPage: FetchPage}) {
    fetchPage      = _.cloneDeep(fetchPage || emptyFetchPage(4));
    fetchPage.page = 0;
    if (!fetchPage.sort_by) {
      fetchPage.sort_by = {
        field: this.defaultSortField,
        direction: defaultSortDirection(),
      };
    }

    this.setFetchPage(fetchPage);
    this.setAccount(account ? _.cloneDeep(account) : null);
    this.clearTransactions();
    this.getTransactionsAggregate();
    this.getNextPage();
  }

  @Action
  async getNextPage() {
    if (!this.hasNextPage) {
      console.warn('no next page for Transactions');
      return;
    }

    this.incrPage()
    if (appConfig.env === 'mock') {
    // if (true) {
      this.addTransactions(fakeTransactions(this.fetchPage));
    } else {
      const page = await transactionsApiClient.getTransactions(this.account ? this.account : null, this.fetchPage)
      this.setHasNextPage(!!page.next)
      this.addTransactions(page.results);
    }
  }

  @Action
  sortBy({fieldName, direction}: { fieldName: string; direction: SortDirection }) {
    this.setSortBy({
      field: fieldName,
      direction: direction,
    });
    this.initializeTransactionsStore({account: this.account ? this.account : null, fetchPage: this.fetchPage});
  }

  @Action
  async getTransactionsAggregate() {
    if (appConfig.env === 'mock') {
      this.addTransactionsAggregate(fakeTransactionsAggregate());
    } else {
      // @ts-ignore
      this.addTransactionsAggregate(await transactionsApiClient.getTransactionsAggregate());
    }
  }

  @Mutation
  clearTransactions() {
    this.transactions          = [];
    this.transactionsAggregate = emptyTransactionsAggregate();
    this.hasNextPage           = true;
  }

  @Mutation
  addTransactions(transactions: Transaction[]) {
    this.transactions = this.transactions.concat(transactions);
  }

  @Mutation
  setFetchPage(fetchPage: FetchPage) {
    this.fetchPage = fetchPage;
  }

  @Mutation
  setAccount(account: Account | null) {
    this.account = account;
  }

  @Mutation
  incrPage() {
    this.fetchPage.page++;
  }

  @Mutation
  setSortBy(sortBy: SortBy) {
    this.fetchPage.sort_by = sortBy;
  }

  @Mutation
  setError(err: Error) {
    console.log('TransactionsStore::setError', err);
  }

  @Mutation
  setHasNextPage(hasNextPage: boolean) {
    this.hasNextPage = hasNextPage;
  }

  @Mutation
  private addTransactionsAggregate(transactionsAggregate: TransactionsAggregate) {
    this.transactionsAggregate = transactionsAggregate;
  }
}


function fakeTransactions(fetchPage: FetchPage): Transaction[] {
  // return _.range(0, fetchPage.page_size).map(i => {
  //   return fakeShop()
  // })
  // @ts-ignore
  return _.range(0, 5).map(i => {
    return fakeTransaction()
  });
}


function fakeTransaction(): Transaction {
  return {
    // @ts-ignore
    id: faker.datatype.number(10000000),
    // @ts-ignore
    dt: DateTime.fromJSDate(faker.date.past()).toFormat('yyyy-MM-dd hh:mm'),
    // @ts-ignore
    child_money: faker.commerce.price(),
    // @ts-ignore
    description: faker.company.companyName(),
    status: 'accepted',
    // @ts-ignore
    paid_to_child: faker.lorem.word(),
    // @ts-ignore
    paid_to_publisher: faker.lorem.word(),
  };
}

function fakeTransactionsAggregate(): TransactionsAggregate {
  return {
    // @ts-ignore
    paid_to_child: faker.datatype.number(100),
    // @ts-ignore
    paid_to_publisher_only: faker.datatype.number(100),
    // @ts-ignore
    accepted: faker.datatype.number(100),
    // @ts-ignore
    pending: faker.datatype.number(100),
    // @ts-ignore
    num_of_helpers: faker.datatype.number(100),
  };
}
