import {BaseServiceWithContext} from '~/lib/api/base/BaseServiceWithContext';
import CommonApiClient from '~/lib/api/clients/CommonApiClient';
import {GetServiceMethods} from '~/lib/api/services/WrapperTypes';
import {handleErrors} from '~/lib/api/types/errors/handleErrors';
import {PageHtml} from '~/lib/api/types/PageHtml';


export default class CommonServiceWithContext extends BaseServiceWithContext<CommonApiClient>
  implements GetServiceMethods<CommonApiClient, 'getCommonDocumentsStatus' | 'login'> {

  @handleErrors()
  getSiteDetail() {
    return this.apiClient.getSiteDetail();
  }

  @handleErrors()
  getPageContent(): Promise<PageContent> {
    // @ts-ignore
    return this.apiClient.getPageContent();
  }

  @handleErrors()
  getRedirect(url: string) {
    return this.apiClient.getRedirect(url);
  }

  @handleErrors()
  getDetail(childId: string) {
    return this.apiClient.getDetail(childId);
  }

  @handleErrors()
  sendContact(subject: string, data: ContactUsForm) {
    return this.apiClient.sendContact(subject, data);
  }

  @handleErrors()
  sendComplaint(complaint: Complaint) {
    return this.apiClient.sendComplaint(complaint);
  }

  @handleErrors()
  // TODO(prod): obfuscate method names
  impersonateUser(childId: string) {
    return this.apiClient.impersonateUser(childId);
  }

  // TODO(prod): obfuscate method names
  @handleErrors()
  stopImpersonation() {
    return this.apiClient.stopImpersonation();
  }

  @handleErrors()
  getPages() {
    return this.apiClient.getPages();
  }

  @handleErrors()
  async _getPageByPath(path: string) {
    return this.apiClient.getPageByPath(path.replaceAll("/", "__")) as Promise<PageHtml>
  }

  async getPageByPath(path: string): Promise<PageHtml> {
    const htmlPage = await this._getPageByPath(path);
    htmlPage.url = htmlPage.url.replaceAll("__", "/");
    return htmlPage as PageHtml
  }

  @handleErrors()
  subscribeToNewsletter(req: SubscribeNewsletter) {
    return this.apiClient.subscribeToNewsletter(req) as Promise<SubscribeNewsletter>
  }

  @handleErrors()
  listBlogPosts(opts: {pageNumber?: number, pageSize?: number} = {}) {
    return this.apiClient.listBlogPosts(opts);
  }

  @handleErrors()
  getBlogPostBySlug(slug: string) {
    return this.apiClient.getBlogPostBySlug(slug);
  }

  @handleErrors()
  getBlogPostBySlugWithSiblings(slug: string) {
    return this.apiClient.getBlogPostBySlugWithSiblings(slug);
  }
}
