import {NuxtError} from '@nuxt/types/app';
import * as util from 'util';
import Vue from 'vue';
import {genUniqueId, invokeAndRet} from '~/lib/util/util';


export function appendErrorId(msg: string, errorId: string): string {
  return `${msg} (Kod błędu: ${errorId})`;
}


export class BaseUIError extends Error {
  errorId: string = genUniqueId()
}


export class WarnError extends BaseUIError {
}


export class NuxtErrorImpl extends Error implements NuxtError {
  constructor(
    public message: string,
    public path?: string,
    public statusCode?: number,
  ) {super(message);}
}


export class NetworkError extends BaseUIError {
  errorId: string

  constructor(err: Error) {
    super(invokeAndRet(() => {
      console.error(err);
      return err.message;
    }));
    this.errorId = attachErrorId(this);
  }
}


export class FatalError extends BaseUIError {
  // errorId: string = genUniqueId('fatal_error_')

  constructor(msg: string, public details: any) {
    super(FatalError.getMessageWithDetails(msg, details));
  }

  static getMessageWithDetails(msg: string, details: any): string {
    return `${msg}${details ? ': ' + util.inspect(details) : ''}`;
  }
}


export class BackendUserError extends BaseUIError {
  constructor(public user_error: string, public status: number) {
    super();
  }
}


export class ClientTimeoutError extends BaseUIError {
  constructor(public err: Error) {
    super()
  }
}


export class UIError extends BaseUIError {
  errorId: string

  constructor(message: string) {
    super(invokeAndRet(() => {
      console.error('UIError:', message);
      return message;
    }));
    this.errorId = attachErrorId(this);
  }
}


export interface WithEmitToChilder {
  emitToChildren?: () => Vue | undefined
}


export interface WithErrorBroker {
  // errorBroker?: ErrorBroker
  errorChannel?: string
}


export function attachErrorId(err: Error): string {
  const e = err as any;
  if (!e.errorId) {
    e.errorId = genUniqueId();
  }
  return e.errorId;
}


export function getErrorId(err: Error): string {
  attachErrorId(err);
  const e = err as any;
  return e.errorId;
}


export function warnError(err: Error): void {
  attachErrorId(err);
  const e = err as any;
  console.warn(appendErrorId(e.message, e.errorId), e);
}
