import Vue from 'vue';
import {attachErrorId, NetworkError} from '~/lib/api/types/errors/Errors';
import {assert, loggerErrorIdFactory} from '~/lib/util/util';
import {RETHROW_IN_VUE_CONTEXT_EVENT_NAME} from '~/plugins/mixin-api-error-handlers';


const {log, logError, loggersWithErrorId} = loggerErrorIdFactory('error-catcher.plugin');

log(null, 'registering listener')

window.onunhandledrejection = function(ev: PromiseRejectionEvent) {
  assert(ev.reason instanceof Error, 'ev.reason not instanceof Error');

  const {log, logWarn, logError} = loggersWithErrorId(attachErrorId(ev.reason));

  log('got unhandled error, sending to Layout component', ev.reason);
  const $layout: Vue = (window as any).__MY_LAYOUT_VUE_COMPONENT;
  if(! $layout || !($layout instanceof Vue)) {
    logError('fatal: cannot get layout component from window', ev, ev.reason);
  } else {
    // @ts-ignore
    if(ev.reason instanceof NetworkError && window['kipHandleConnection']) {
      // @ts-ignore
      window['kipHandleConnection']();
    }
    $layout.$emit(RETHROW_IN_VUE_CONTEXT_EVENT_NAME, ev.reason as Error);
    ev.preventDefault();
  }
};

window.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
  let errorId;
  if(error) {
    errorId = attachErrorId(error);
  }
  logError(errorId, 'window.onerror', event, error);
}

// export default (ctx: Context) => {
//   log(null, 'registering listener')
//
//   window.onunhandledrejection = function(ev: PromiseRejectionEvent) {
//     assert(ev.reason instanceof Error, 'ev.reason not instanceof Error');
//
//     const {log, logWarn, logError} = loggersWithErrorId(attachErrorId(ev.reason));
//
//     log('got unhandled error, sending to Layout component', ev.reason);
//     const $layout: Vue = (window as any).__MY_LAYOUT_VUE_COMPONENT;
//     if(! $layout || !($layout instanceof Vue)) {
//       logError('fatal: cannot get layout component from window', ev, ev.reason);
//     } else {
//       $layout.$emit(RETHROW_IN_VUE_CONTEXT_EVENT_NAME, ev.reason as Error);
//       ev.preventDefault();
//     }
//   }
// };
