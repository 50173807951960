export const FORM_PASSWORDS_DONT_MATCH = 'Wpisane hasła nie są takie same.';

export const POMAGAJACY_REGISTRATION_SUCCESSFUL_MESSAGE = 'Na Twojego maila wysłaliśmy link do aktywacji konta. Sprawdź maila i aktywuj konto, aby móc się logować.';



export const DODAJ_PODOPIECZNEGO_SUCCESS_MESSAGE = 'Na podany adres e-mail wysłaliśmy wiadomość, sprawdź pocztę, aby dokończyć' +
    ' proces zakładania konta. Jeśli nie widać wiadomości w skrzynce odbiorczej,' +
    ' sprawdź folder "spam".';


export const PODOPIECZNI_WYBIERZ_PODOPIECZNEGO_SUBTITLE = 'Wybierz osobę, która będzie otrzymywać środki, za każdym razem, gdy zrobisz zakupy w sklepie partnerskim.'
export const PODOPIECZNI_WYBIERZ_PODOPIECZNEGO_I_ROB_ZAKUPY_SUBTITLE = 'Zanim przejdziesz do sklepu, wybierz osobę, która otrzyma wsparcie, kiedy zrobisz zakupy.'

export const NEWSLETTER_THANK_YOU = 'Dziękujemy za subskrypcję.'


// Validation error messages

export const FIELD_IS_REQUIRED = 'To pole jest wymagane';
export const WRONG_IBAN_NUMBER = 'Błędny numer konta bankowego';

export const WRONG_ALLEGRO_PAYMENT_NUMBER = 'Błędny format numeru płatności Allegro';

export const YOU_MUST_CHOOSE_FILE = 'Nie wybrano pliku';
export const YOU_MUST_CHOOSE_SINGLE_FILE = 'Należy wybrać tylko jeden plik';
export const INCORRECT_FILE_EXTENSIONS__CORRECT_ONES_ARE = 'Błędne rozszerzenie pliku. Obsługiwane rozszerzenia to:'
