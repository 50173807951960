import { render, staticRenderFns } from "./PrettyContextMenu.vue?vue&type=template&id=3e2a0e91&scoped=true&"
import script from "./PrettyContextMenu.vue?vue&type=script&lang=ts&"
export * from "./PrettyContextMenu.vue?vue&type=script&lang=ts&"
import style0 from "./PrettyContextMenu.vue?vue&type=style&index=0&id=3e2a0e91&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2a0e91",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContextMenuTail: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/util/context_menu/ContextMenuTail.vue').default})
