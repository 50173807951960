

if(typeof window !== undefined) {
  console.debug = (x: any, ...args: any[]) => {
    console.log(`%c${x}`, 'color: #adadad', ...args);
  }
}

import {Component} from 'nuxt-property-decorator';
import { vueWindowSizeMixin } from 'vue-window-size';
import RethrowErrorInVueContextComponent from '~/components/base/RethrowErrorInVueContextComponent';
import {windowResizeMixinForRoot} from '~/lib/util/window_resize_mixin_for_root';

@Component({
  mixins: [windowResizeMixinForRoot],
})
export default class DefaultLayout extends RethrowErrorInVueContextComponent {
}
