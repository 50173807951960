import {BaseServiceWithContext} from '~/lib/api/base/BaseServiceWithContext';
import ShopApiClient from '~/lib/api/clients/ShopApiClient';
import {CancelToken} from 'axios';
import {GetServiceMethods} from '~/lib/api/services/WrapperTypes';
import {handleErrors} from '~/lib/api/types/errors/handleErrors';
import {FetchPage} from '~/lib/api/types/FetchPage';
import {PageResponse} from '~/lib/api/types/PageResponse';
import {ShopCategory} from '~/lib/api/types/ShopCategory';
import { REQUEST_CANCELLED } from '../base/BaseApiClient.js';


export class ShopServiceWithContext extends BaseServiceWithContext<ShopApiClient>
  implements GetServiceMethods<ShopApiClient> {

  @handleErrors()
  getShopCategoryList() {
    return this.apiClient.getShopCategoryList() as Promise<ShopCategory[]>;
  }

  @handleErrors()
  getShopList(fetchPage: FetchPage, category: ShopCategory | null) {
    return this.apiClient.getShopList(fetchPage, category) as Promise<PageResponse<Shop>>;
  }

  @handleErrors()
  getCancellableShopList(fetchPage: FetchPage, category: ShopCategory | null, cancelToken?: CancelToken) {
    return this.apiClient.getShopList(fetchPage, category, cancelToken) as Promise<PageResponse<Shop> | typeof REQUEST_CANCELLED>;
  }

  @handleErrors()
  async getLandingPageShops() {
    const page = await (this.apiClient.getLandingPageShops() as Promise<PageResponse<Shop>>);
    return page.results;
  }

  @handleErrors()
  async getShopById(id: number): Promise<Shop | undefined> {
    const page = await (this.apiClient.getShopById(id) as Promise<PageResponse<Shop>>);
    return page.results.length === 1 ? page.results[0] : undefined;
  }
}
