import {NuxtAxiosInstance} from '@nuxtjs/axios';
import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import axios from 'axios';
import {AppConfig} from '~/etc/env';
import {ErrorResponse} from '~/lib/api/types/errors/ErrorResponse';

export const REQUEST_CANCELLED = Object.freeze({'REQUEST_CANCELLED': true});

export function is_cancelled_response(resp: any): resp is typeof REQUEST_CANCELLED {
  return resp === REQUEST_CANCELLED;
}

type Method = 'get' | 'post' | 'patch' | 'put' | 'delete';

export type ApiResponse<T> = T | ErrorResponse;

export class BaseApiClient {
  protected $axios: NuxtAxiosInstance;
  protected appConfig: AppConfig;

  private defaultRequestConfig: AxiosRequestConfig = {
    validateStatus: function (status) {
      return true;
    }
  };

  constructor($axios: NuxtAxiosInstance, appConfig: AppConfig) {
    this.$axios = $axios
    this.appConfig = appConfig
  }

  public static extractResponseSync<T>(resp: AxiosResponse<T>, requestUrl: string): ApiResponse<T> {
    const isOk = resp.status >= 200 && resp.status < 400;
    if(isOk) {
      return resp.data;
    } else {
      return new ErrorResponse(resp, requestUrl);
    }
  }

  public static async extractResponse<T>(respPromise: Promise<AxiosResponse<T>>, requestUrl: string): Promise<ApiResponse<T>> {
    const resp = await respPromise;
    return this.extractResponseSync(resp, requestUrl);
  }

  protected async $get<T>(
    path: string,
    query?: {[key: string]: string | number | boolean | null},
    withResponseHeaders: boolean = false,
    requestConfig: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    if('query' in requestConfig) {
      throw new Error('Use the query parameter instead of requestConfig.query');
    }

    return this.$req<T>('get', path, null, {params: query, ...requestConfig}, withResponseHeaders)
  }

  protected async $cancellable_get<T>(
    path: string,
    cancelToken?: CancelToken,
    query?: {[key: string]: string | number | boolean | null},
  ): Promise<ApiResponse<T> | typeof REQUEST_CANCELLED> {
    try {
      return await this.$req<T>('get', path, null, {params: query, cancelToken});
    } catch(err) {
      if (axios.isCancel(err)) {
        console.warn('Request to %s cancelled due to %s', path, err);
        return REQUEST_CANCELLED;
      } else {
        throw err;
      }
    }
  }

  protected async $post<T>(path: string, data?: any): Promise<ApiResponse<T>> {
    return this.$req('post', path, data)
  }

  protected async $patch<T>(path: string, data: any): Promise<ApiResponse<T>> {
    return this.$req<T>('patch', path, data)
  }

  protected async $put<T>(path: string, data: any): Promise<ApiResponse<T>> {
    return this.$req<T>('put', path, data)
  }

  protected async $delete<T>(path: string): Promise<ApiResponse<T>> {
    return this.$req<T>('delete', path)
  }

  protected async $postFormData<T>(path: string, data: FormData): Promise<ApiResponse<T>> {
    return this.$formData<T>('post', path, data);
  }

  protected async $patchFormData<T>(path: string, data: FormData): Promise<ApiResponse<T>> {
    return this.$formData<T>('patch', path, data);
  }

  protected async $formData<T>(method: Extract<Method, 'post' | 'put' | 'patch'>, path: string, data: FormData): Promise<ApiResponse<T>> {
    return this.$req<T>(method, path, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  protected async $req<T>(
    method: Method,
    url: string,
    data?: any,
    config: AxiosRequestConfig = {},
    withResponseHeaders: boolean = false
  ): Promise<ApiResponse<T>> {
    // Heroku has 30s timeout, so we allow for it to pass (so we get timeout error from backend),
    // and if don't then we display client side timeout. This is especially useful in the case of
    // slow internet during large file uploads where gunicorn workers are restarted and heroku
    // router just keeps the connection open and we see Pending connection in dev tools
    // indefinitely.
    //
    // NOTE: we don't update this value in `nuxt.axios.config.ts` because it didn't have any effect
    // there.
    config.timeout = 40_000;

    const rawResp: AxiosResponse<T> = await this.$axios.request({
      ...this.defaultRequestConfig,
      ...config,
      method,
      url,
      data
    });

    const resp = BaseApiClient.extractResponseSync(rawResp, url);

    if (withResponseHeaders) {
      // @ts-ignore
      resp.__responseHeaders = rawResp.headers;
    }

    return resp;
  }
}
