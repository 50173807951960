import {Transaction, TransactionStatus} from '~/lib/api/types/Transaction';


export interface TransactionView {
  id: number
  date: string
  childMoney: string
  shopName: string
  status: string
  statusLabel: string
  statusLabelCssClass: string
  xsTableCellCssClass: string
  amountPaidToChild: string
  amountPaidToCublisher: string
}


export type TransactionStatusLabel =
  'Zaakceptowana'
  | 'Odrzucona'
  | 'Oczekująca'
  | 'Nieznany'
  | 'Wypłacona'
  | 'Gotowa do wypłaty'

export type ExtendedTransactionStatus = TransactionStatus | 'paid_to_child' | 'paid_to_publisher'
export type StatusLabelsWithDescription = Extract<TransactionStatusLabel, 'Oczekująca' | 'Odrzucona' | 'Zaakceptowana' | 'Gotowa do wypłaty' | 'Wypłacona'>;


export function toTransactionsView(transactions: Transaction[], account: Account): TransactionView[] {
  return transactions.map(t => toTransactionView(account, t))
}

const mapping: Record<TransactionStatus | string, string> = {
  'accepted': 'uppercase font-bold text-green-500',
  'rejected': 'uppercase font-bold text-red-500',
  'removed': 'uppercase font-bold text-gray-500',
  'pending': 'uppercase font-bold text-kip-yellow',
  'unknown': 'uppercase font-bold text-gray-500',
}

const mappingTableCell: Record<TransactionStatus | string, string> = {
  'accepted': 'border border-green-500',
  'rejected': 'border border-red-500',
  'removed': 'border border-gray-500',
  'pending': 'border border-kip-yellow',
  'unknown': 'border border-gray-500',
}

function toStatusLabelClass(status: TransactionStatus | string) {
  return mapping[status] || '';
}

function toXsTableCellClass(status: TransactionStatus | string) {
  return mappingTableCell[status] || '';
}

export function toTransactionView(account: Account, t: Transaction): TransactionView {
  return {
    id: t.id,
    date: t.dt,
    childMoney: t.child_money,
    shopName: t.description,
    status: t.status,
    statusLabel: mapStatusLabel(account, t),
    statusLabelCssClass: toStatusLabelClass(t.status),
    xsTableCellCssClass: toXsTableCellClass(t.status),
    amountPaidToChild: t.paid_to_child,
    amountPaidToCublisher: t.paid_to_publisher,
  };
};


const statusLabels: Record<ExtendedTransactionStatus, TransactionStatusLabel> = {
  accepted: 'Zaakceptowana',
  rejected: 'Odrzucona',
  removed: 'Odrzucona',
  pending: 'Oczekująca',
  unknown: 'Nieznany',

  // non statuses
  paid_to_child: 'Wypłacona',
  paid_to_publisher: 'Gotowa do wypłaty',
};

const userStatusLabels: Record<ExtendedTransactionStatus, TransactionStatusLabel> = {
  accepted: 'Zaakceptowana',
  rejected: 'Odrzucona',
  removed: 'Odrzucona',
  pending: 'Oczekująca',
  unknown: 'Nieznany',

  // non statuses
  paid_to_child: 'Zaakceptowana',
  paid_to_publisher: 'Zaakceptowana',
};

export function mapStatusLabel(account: Account, t: Transaction): string {
  let extendedStatus: Transaction['status'] | 'paid_to_child' | 'paid_to_publisher' = t.status;

  if (t.status === 'accepted') {
    if (t.paid_to_child) {
      extendedStatus = 'paid_to_child'
    } else if (t.paid_to_publisher) {
      extendedStatus = 'paid_to_publisher'
    }
  }

  if (account.role === 'parent') {
    return statusLabels[extendedStatus] || 'Nieznany'
  } else {
    return userStatusLabels[extendedStatus] || 'Nieznany'
  }
}
