import {Component, Prop} from 'nuxt-property-decorator';
import Vue from 'vue';
import {
  FORM_INPUT_CLEAR_VALIDATION_ERRORS_EVENT_NAME,
  FORM_INPUT_VALIDATION_ERROR_EVENT_NAME,
} from '~/lib/constants/events';

@Component
export default class BaseInputComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  label!: string

  @Prop({
    type: String,
    required: false,
  })
  additionalDescription!: string

  @Prop({
    type: String,
    required: true,
  })
  name!: string

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean | undefined

  @Prop({
    type: Number,
    required: false,
  })
  minlength!: number | undefined

  @Prop({
    type: Number,
    required: false,
  })
  maxlength!: number | undefined

  showInvalid = false
  message: string = ''


  created() {
    this.$on(FORM_INPUT_VALIDATION_ERROR_EVENT_NAME, (message: string) => {
      this.onValidationError(message);
    });
    this.$on(FORM_INPUT_CLEAR_VALIDATION_ERRORS_EVENT_NAME, () => {
      this.onClearValidationErrors();
    });
  }

  invalidCallback(event: Event) {
    this.showInvalid = true;
  }


  onValidationError(message: string) {
    this.showInvalid = true;
    this.message = message;
  }

  onClearValidationErrors() {
    this.showInvalid = false;
    this.message = '';
  }

  get inputId(): string {
    return `form-${this.name}-validation-message`
  }

  // doValidate(): string | undefined {
  //   return undefined;
  // }
}
