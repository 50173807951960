import _ from "lodash";
import micromark from 'micromark';
// import {gfm, gfmHtml} from 'micromark-extension-gfm'

import * as matter from 'gray-matter';
import { globalStore } from "./accessors/store-accessor";
import { commonService } from "./accessors/api";

export const PAGE_CONTENT_CSS_CLASSES: Partial<{[key in keyof PageContent]: string}> = {
  o_fundacji            : '',
  newsletter_opis       : '',
  newsletter_zgoda      : '',
  strona_sklepy_opis    : '',
  helpers_header        : '',
  helpers_jak_to_dziala : 'flex flex-col items-center w-11/12 mx-auto',
  parents_dlaczego_warto: 'dla-potrzebujacych-dlaczego-warto-box',
  parents_header        : '',
  parents_jak_to_dziala : 'grid grid-flow-row gap-16',
  helpers_kim_sa_osoby  : '',
}

export const PAGE_CONTENT_KEYS: {[key in keyof PageContent]: string} = {
  o_fundacji            : 'o-fundacji',
  newsletter_opis       : 'newsletter',
  newsletter_zgoda      : 'newsletter-footer',
  strona_sklepy_opis    : 'strona-sklepy-opis',
  helpers_header        : 'dla-pomagajacych-naglowek',
  helpers_jak_to_dziala : 'jak-to-dziala',  // dp - dla pomagających
  parents_dlaczego_warto: 'dlaczego-warto',
  parents_header        : 'dla-potrzebujacych-naglowek',
  parents_jak_to_dziala : 'jak-to-dziala',
  helpers_kim_sa_osoby  : 'kim-sa-osoby',
  sekcja_pomoc          : 'pomoc',
  wtyczka_sekcja_pomoc  : 'wtyczka-pomoc',
}

export function parsePageContent(page_content: PageContent): ParsedPageContent {
  // @ts-ignore
  return _(page_content)
    .toPairs()
    .map(([key, markdown_src]) => {
      // @ts-ignore
      const { data, content, ...rest } = matter(markdown_src, { excerpt: true, excerpt_separator: '<!--more-->' })
      //                                                      \_________________________________________________/
      //                                                                     |
      //                                                                     \ From @nuxt/content

      const html_output = micromark(content.trim()/*, undefined, {
        extensions: [gfm()],
        htmlExtensions: [gfmHtml()]
      }*/);

      return [key, {
        data,
        markdown_src,
        html_output,
      } as ParsedPageContentString];
    })
    .fromPairs()
    .value();
}

export function isParsedPageContent(obj: any): obj is ParsedPageContent {
  // Note: regarding @ts-ignores below - we cannot use "target": "ES2022" in tsconfig.json for
  //   Object.hasOwn availability because of old Nuxt version which uses old typescript version.
  return (
    _.isObject(obj)
    // @ts-ignore
    && Object.hasOwn(obj, 'html_output')
    // @ts-ignore
    && Object.hasOwn(obj, 'markdown_src')
    // @ts-ignore
    && Object.hasOwn(obj, 'data')
  );
}

// export function toSectionData(obj: ParsedPageContentString, key: keyof PageContent) {
export function toSectionData(parsedPageContent: ParsedPageContent, key: keyof PageContent): SectionData {
  const obj = parsedPageContent[key];

  return {
    ...obj,
    get slug() {
      return PAGE_CONTENT_KEYS[key];
    },
    get key() {
      return PAGE_CONTENT_KEYS[key];
    },
    get title(): string | undefined {
      return obj.data.title;
    },
    get html(): string {
      return obj.html_output;
    },
    get cssClass() {
      return PAGE_CONTENT_CSS_CLASSES[key] ?? '';
    },
  }
}

export type SectionData = ParsedPageContentString & {
  slug: string
  key: string
  title: string | undefined
  html: string
  cssClass: string
}

export async function syncPageContent() {
  // TODO: Refactor into lib function and call it during navigation
  // @ts-ignore
  const page_content: PageContent = await commonService.withErrorBroker().getPageContent();

  globalStore.setParsedPageContent(parsePageContent(page_content));
}
