import _ from 'lodash';
import Vue from 'vue';
import tailwindConfig from '~/tailwind.config';

export type TailwindBreakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export interface KipTailwindTheme {
  colors: {
    kipViolet: string
  },
  breakpoints: {
    'xxs': number
    'xs': number
    'sm': number
    'md': number
    'lg': number
    'xl': number
    '2xl': number
  },
  // revBreakpoints: {[key: number]: WindowSize}
}

declare module 'vue/types/vue' {
  interface Vue {
    $tailwindTheme: KipTailwindTheme
  }
}

const screens = tailwindConfig.theme.screens;
// @ts-ignore
const breakpoints = _(screens).map((minmax: {min: string, max: string}, size) => {
  if(!minmax.max) {
    return null;
  }
  const max = minmax.max.replace('px', '');
  return [size, parseInt(max)]
}).filter(x => !!x).fromPairs().value();
// const breakpoints = _(screens).map((minmax: string, size) => {
//   if(size === 'xs') {
//     return ['xs', '0px']
//   } else {
//     return [size, parseInt(minmax.replace('px', ''))]
//   }
// }).filter(x => !!x).fromPairs().value();
Vue.prototype.$tailwindTheme = {
  colors: {
    kipViolet: tailwindConfig.theme.extend.colors['kip-violet']
  },
  breakpoints: breakpoints,
  // revBreakpoints: _(breakpoints).map((size, width) => [width, size]).fromPairs().value()
};
