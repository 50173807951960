import { isMobile } from 'mobile-device-detect';
import Vue from 'vue';
import { TEXTS } from '~/content/texts';
import * as constants from '~/lib/constants';

const mixin = Vue.extend({
  data() {
    return {
      ...constants
    }
  },
  computed: {
    $isMobile(): boolean {
      return isMobile
    },
    $texts() {
      return TEXTS;
    },
    $isTouchScreen(): boolean {
      // From https://github.com/ndelvalle/v-click-outside/blob/2eeba8c188c34059eb4b1cb247af99149fb93ed8/src/v-click-outside.js
      const HAS_WINDOWS = typeof window !== 'undefined';
      const HAS_NAVIGATOR = typeof navigator !== 'undefined';
      const IS_TOUCH: boolean =
        HAS_WINDOWS &&
        ('ontouchstart' in window ||
          (HAS_NAVIGATOR && navigator.maxTouchPoints > 0));

      return IS_TOUCH;
    },
  },
  methods: {
    async $authExecuteLogout() {
      await this.$auth.logout()
      await this.$router.push({name: 'index'});
    },
  },

});

Vue.mixin(mixin);

