import {ApiResponse, BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {IsSuccessResponse} from '~/lib/api/types/responses/IsSuccessResponse';
import {UserCreated} from '~/lib/api/types/responses/UserCreated';
import ChildView from '~/lib/types/ChildView';


export default class AuthApiClient extends BaseApiClient {
  login(username: string, password: string) {
    const data = {
      username: username,
      password: password
    }
    return this.$post(`/auth/login/`, data)
  }

  activate(token: string) {
    const data = {
      token: token
    }
    return this.$post<IsSuccessResponse>(`/auth/activate/`, data)
  }

  confirmParentEmail(token: string) {
    const data = {
      token: token
    }
    return this.$post<IsSuccessResponse>(`/auth/confirm-parent-email/`, data)
  }

  registerBasic(email: string, password: string, child: ChildView) {
    const data = {
      email: email,
      password: password,
      child: child.id
    }
    return this.$post<UserCreated>(`/register/user/`, data)
  }

  getAccount() {
    return this.$get<Account>('/user/me/')
  }

  registerParent(r: RegisterParentRequest): Promise<ApiResponse<UserCreated>> {
    const data = new FormData()
    data.append('email', r.email)
    data.append('password', r.password)
    data.append('phone_number', r.phoneNumber)
    data.append('child_full_name', r.childFullName)

    // deprecated
    data.append('child_birth_year', r.childBirthYear)

    data.append('child_note', r.childNote)
    if (r.childPhoto) {
      data.append('child_photo', r.childPhoto)
    }
    return this.$postFormData<UserCreated>(`/register/parent/`, data)
  }

  resetPassword(email: string) {
    const data = {
      email: email
    }
    return this.$post(`/rest-auth/password/reset/`, data)
  }

  resetPasswordConfirm({uid, token, newPassword, newPasswordConfirm}: {uid: string, token: string, newPassword: string, newPasswordConfirm: string}) {
    const data = {
      uid: uid,
      token: token,
      new_password1: newPassword,
      new_password2: newPasswordConfirm
    }
    return this.$post(`/rest-auth/password/reset/confirm/`, data)
  }

  changePassword(oldPassword: string, newPassword: string) {
    const data = {
      old_password: oldPassword,
      new_password: newPassword
    }
    return this.$put(`/user/me/change_password/`, data)
  }

  isDuplicateChildName(fullName: any) {
    const data = {
      full_name: fullName
    }
    return this.$post(`/register/parent/check_duplicate/`, data)
  }
}
