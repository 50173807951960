
import {Component} from 'nuxt-property-decorator';
import ErrorHandlingComponent from '~/components/base/ErrorHandlingComponent';
import ErrorCatcher from '~/components/ErrorCatcher.vue';
import {PAGE_CHANNEL_NAME} from '~/store/constants';

@Component({
  components: {ErrorCatcher},
})
export default class Page extends ErrorHandlingComponent {
  success: string = ''
  warn: string = ''
  constructor() {
    super();
    this.channelName = PAGE_CHANNEL_NAME
  }
}

