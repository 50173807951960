import {ApiResponse, BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {PageHtml} from '~/lib/api/types/PageHtml';


export default class CommonApiClient extends BaseApiClient {
  getPageContent() {
    return this.$get(`/page-content`)
  }

  getSiteDetail() {
    return this.$get(`/children/`)
  }

  getRedirect(url: any) {
    return this.$get(url, {maxRedirects: 0})
  }

  getDetail(childId: any) {
    return this.$get<Child>(`/children/${childId}/`)
  }

  sendContact(subject: string, data: ContactUsForm) {
    return this.$post('/contact/', {
      subject: `${subject} (${data.firstName} ${data.lastName})`,
      content: data.message,
      email: data.email,
    } as ContactUsRequest)
  }

  sendComplaint(complaint: Complaint) {
    return this.$post('/complaint/', {
      shop_id: complaint.shop!.id+'',
      transaction_date: complaint.transactionDateTime,
      order_number: complaint.orderNumber,
      payment_number: complaint.paymentNumber,
      order_amount: complaint.orderAmount,
    } as ComplaintRequest)
  }

  impersonateUser(childId: string) {
    // TODO(prod): obfuscate API
    return this.$put('/auth/impersonate/', {
      impersonate_child_id: childId,
    })
  }

  stopImpersonation() {
    // TODO(prod): obfuscate API
    return this.$delete('/auth/impersonate/')
  }

  getPages() {
    return this.$get<PageHtml>(`/pages/`)
  }

  getPageByPath(path: string) {
    return this.$get<PageHtml>(`/pages/${path}`)
  }

  subscribeToNewsletter(req: SubscribeNewsletter) {
    return this.$post<SubscribeNewsletter>('/newsletter/', req)
  }

  listBlogPosts(opts: {pageNumber?: number, pageSize?: number} = {}) {
    const pageNumber = opts.pageNumber || 1;
    const pageSize = opts.pageSize || 10;

    const queryString = `?page=${pageNumber}&page_size=${pageSize}`
    return this.$get<BlogPost[]>('/blog/posts'+queryString, undefined, true);
  }

  getBlogPostBySlug(slug: string) {
    return this.$get<BlogPost>('/blog/posts/' + slug)
  }

  getBlogPostBySlugWithSiblings(slug: string) {
    return this.$get<BlogPostWithSiblingsModel>('/blog/posts/' + slug + '/with-siblings')
  }
}
