
import Vue from 'vue'
import { globalStore } from '~/lib/accessors/store-accessor';
import {Component, Prop, Watch, On} from 'nuxt-property-decorator';
import BaseFormComponent from './Form/BaseFormComponent';

@Component({
  components: {},
})
export default class VodalMain extends BaseFormComponent {
  get vodalVisible() {
    return globalStore.vodalVisible;
  }

  set vodalVisible(value: boolean) {
    globalStore.closeVodal();
  }

  get vodalText() {
    return globalStore.vodalText;
  }

  get vodalHtml() {
    return globalStore.vodalHtml;
  }
}
