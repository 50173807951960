import {SortDirection} from '~/lib/api/types/Field';


export interface SortBy {
  field: string;
  direction: SortDirection;
}

export interface FetchPage {
  page: number
  page_size: number
  search: string
  seed: number
  sort_by?: SortBy
}

export function emptyFetchPage(page_size: number = 8): FetchPage {
    return {
      page: 0,
      page_size: page_size,
      search: '',
      seed: 0,
    };
}
