
import {Component} from 'nuxt-property-decorator';
import Vue from 'vue';
import appConfig from '~/lib/util/appConfig';


@Component
export default class BottomBar extends Vue {
  baseYear = 2021;

  goodFacesUrl   = 'https://unsplash.com/@goodfacesagency'
  xavierUrl      = 'https://unsplash.com/@xaviermoutonphotographie'
  hapsUrl        = 'https://haps.studio/'
  developmentUrl = 'https://google.com'

  get copyrightYears(): string {
    const currentYear = new Date().getFullYear();
    if (currentYear <= this.baseYear) {
      return this.baseYear + '';
    } else {
      return `${this.baseYear} - ${currentYear}`;
    }
  }

  get version(): string {
    return appConfig.version;
  }
}
