
import _ from 'lodash';
import Vue, {PropOptions} from 'vue';
import PageSubTitle from '~/components/page-components/PageSubTitle.vue';
import PageTitle from '~/components/page-components/PageTitle.vue';
import PageSection from '~/components/PageSection.vue';
import DebugMessage from '~/components/util/DebugMessage.vue';
import {getErrorId} from '~/lib/api/types/errors/Errors';
import appConfig from '~/lib/util/appConfig';
import {loggerErrorIdFactory} from '~/lib/util/util';

const {logError} = loggerErrorIdFactory('<error/>');

export default Vue.extend({
  components: {DebugMessage, PageSubTitle, PageTitle, PageSection},
  props: {
    error: {
      type: Object,
      required: true,
    } as PropOptions<Error>,
  },
  computed: {
    env() {
      return appConfig.env;
    },
    errorId(): string {
      return getErrorId(this.error);
    },
    errorMessage(): string {
      const err = this.error as any;
      return `${this.error.message}${err.details ? ': '+JSON.stringify(err.details, undefined, 2) : ''}`
    },
    pathNotFound(): string | undefined {
      const err = this.error as any;
      if(err.path && err.path.startsWith('/pages/')) {
        const xs = err.path.split('/');
        return 'Brak strony w Django Admin: '+xs[2].replace('__', '/')
      } else {
        return err.path;
      }
    },
  },
  mounted() {
    logError(this.errorId, '(mounted)', this.error);
  },
  watch: {
    error: {
      deep: true,
      handler(e) {
        logError(this.errorId, '(watch)', e);
      },
    },
  },
})
