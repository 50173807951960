
import Vue from 'vue';
import {Env} from '~/etc/env';
import appConfig from '~/lib/util/appConfig';


export default Vue.extend({
  computed: {
    env(): Env {
      // return appConfig.env;
      return appConfig.env;
    }
  }
})
