import _ from 'lodash';
import {ErrorResponse} from '~/lib/api/types/errors/ErrorResponse';


export function extractDjangoErrorMessages(body: any): Record<string | 'non_field_errors', string[]> | undefined {
  if(_.isPlainObject(body)) {
    return body;
  } else {
    return undefined
  }
}


function expandToDashCase(fieldLabels: Record<string, string> = {}) {
  return {
    ...fieldLabels,
    ..._(fieldLabels).toPairs().map(([k, v]) => [_.snakeCase(k), v]).fromPairs().value()
  };
}


export function handleDjangoBadRequest(resp: ErrorResponse, fieldLabels?: Record<string, string>, noLabelPrefix?: boolean): string {
  fieldLabels = expandToDashCase(fieldLabels);

  const body = extractDjangoErrorMessages(resp.body);
  if (body) {
    let msgs: string[] = [];
    if(body['non_field_errors']) {
      msgs = msgs.concat(body['non_field_errors']);
      delete body['non_field_errors'];
    }
    if(fieldLabels) {
      for(const k of _.keys(body)) {
        const errors = body[k];
        const label = fieldLabels[k];
        if(label) {
          const msg = errors.join(', ');
          msgs = msgs.concat(noLabelPrefix ? msg : `${label}: ${msg}`);
        } else {
          console.warn(`No fieldLabels for field '${k}', fieldLabels=`, fieldLabels, `resp=`, resp);
        }
      }
    } else {
      const b = _.omit(body, 'non_field_errors');
      if(_.keys(b).length > 0) {
        console.warn(`No fieldLabels for errors:`, b);
      }
    }
    if(msgs.length > 0) {
      return msgs.join('; ');
    } else {
      return 'Błąd aplikacji (kod 4)';
    }
  } else {
    return 'Błąd aplikacji (5)';
  }
}

export function extractDjangoErrorMessage(resp: ErrorResponse): string {
  const body = resp.body;

  if(! body) return "Błąd aplikacji (kod 6)";

  if(_.isString(body)) {
    return body;
  }

  if(_.isPlainObject(body)) {
    if('user_error' in body) {
      return body['user_error'];
    }
  }

  console.warn(
    'Showing code 7 error message to the user ' +
    'because of unsupported error response:',
    body
  );

  return "Błąd aplikacji (kod 7)";
}
