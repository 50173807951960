import _ from 'lodash';
import {Component} from 'nuxt-property-decorator';
import Vue from 'vue';
import BaseInputComponent from '~/components/Form/BaseInputComponent';
import {FatalError, WarnError} from '~/lib/api/types/errors/Errors';
import {
  FORM_INPUT_CLEAR_VALIDATION_ERRORS_EVENT_NAME,
  FORM_INPUT_VALIDATION_ERROR_EVENT_NAME,
} from '~/lib/constants/events';
import {FIELD_IS_REQUIRED} from '~/lib/localization';
import {assert, assertNotifyOnly, breadFirstSearch, castToVue, isElementInViewport} from '~/lib/util/util';
import {CustomValidator} from '~/lib/util/validators';

type Ctor<T> = { new(...args: any[]): T };

@Component
export default class BaseFormComponent extends Vue {
  protected _baseData: any;

  loading: boolean               = false
  success: string                = ''
  validationErrorMessage: string = ''
  config                         = {
    resetDataAfterSuccessfulSubmit: true,
    dontResetTheseFields: new Set<string>()
  };
  formData: any                  = {}

  customValidators: Set<CustomValidator> = new Set<CustomValidator>()

  addCustomValidator(validator: CustomValidator) {
    this.customValidators.add(validator);
  }

  created() {
    this.customValidators.clear();
    this._cloneBaseData();
  }

  _componentName(): string {
    return this.constructor.name;
  }

  resetData(): void {
    if (!this.config.resetDataAfterSuccessfulSubmit) {
      return;
    }

    const _ignore = ['config', 'success', 'customValidators'].concat([...this.config.dontResetTheseFields.values()]);
    const ignore = new Set(_ignore)
    console.log(`${this._componentName()} :: resetData (ignore = ${JSON.stringify(_ignore)}`)
    for (const [k, v] of _.entries(this._baseData)) {
      if (!ignore.has(k)) {
        (this as any)[k] = v;
      }
    }
  }

  /**
   * For some reason if we use 'validate' method name then it is undefined. Probably collision with some Vue internals.
   * @param value
   * @param msg
   */
  doValidate(value: any, msg: string): boolean {
    if (!value) {
      this.validationErrorMessage = msg;
      return false;
    } else {
      this.validationErrorMessage = '';
      return true;
    }
  }

  doValidateForm(vFormRoot: Vue, data: Record<string, any>): boolean {
    assert(vFormRoot, '$refs.vFormRoot must be set');
    // const formCheckboxes: Vue[] = breadFirstSearch<Vue>(vFormRoot, '$children').filter($el => $el instanceof
    // FormCheckbox);
    const inputComponents: Vue[] = breadFirstSearch<Vue>(vFormRoot, '$children').filter($el => $el instanceof BaseInputComponent);
    let isValid                  = true;

    let topHeight                      = 99999;
    let scrollToInput: Vue | undefined = undefined;
    const stack                        = (elem: Vue) => {
      if (!isElementInViewport(elem.$el as HTMLElement)) {
        const prevTopHeight = topHeight;
        topHeight           = Math.min(topHeight, (elem.$el as HTMLElement).offsetTop || 99999);
        if (prevTopHeight !== topHeight) {
          scrollToInput = elem;
        }
      }
    };

    const invalidFields: Set<string> = new Set();

    // Validate checkboxes
    for (const inputComponent of inputComponents) {
      const name                 = inputComponent.$props['name'];
      const hasNameAttr: boolean = assertNotifyOnly(name, 'Input field doesn\'t have a \'name\' attribute:', inputComponent);

      if ('required' in inputComponent.$props
        && inputComponent.$props['required']
      ) {
        if (!hasNameAttr) {
          isValid = false;
          invalidFields.add(name);
          throw new FatalError('Input field doesn\'t have name attribute but is required:', inputComponent);
        }

        const actualValue = data[name];

        if (!actualValue) {
          isValid = false;
          invalidFields.add(name);
          inputComponent.$emit(FORM_INPUT_VALIDATION_ERROR_EVENT_NAME, FIELD_IS_REQUIRED);
          stack(inputComponent);
        } else if (!invalidFields.has(name)) {
          inputComponent.$emit(FORM_INPUT_CLEAR_VALIDATION_ERRORS_EVENT_NAME);
        }
      }

      // Note: this doesn't work if field has initially invalid value. Only after user performs some
      // change in the field native validation will be triggered. I tried to trigger it manually but
      // its hard or not possible.
      const htmlInputElement = getElemByType(inputComponent.$el as HTMLElement, HTMLInputElement);
      const htmlTextAreaElement = getElemByType(inputComponent.$el as HTMLElement, HTMLTextAreaElement);
      const htmlInputText = htmlInputElement || htmlTextAreaElement;
      if(htmlInputText) {
        if(! htmlInputText.checkValidity()) {
          htmlInputText.reportValidity();
          isValid = false;
        }
      }
    }

    // Custom validators
    for (const customValidator of this.customValidators) {
      const errorMessage           = customValidator.validator();
      const fieldName              = customValidator.fieldName();
      const sourceInputRefs: Vue[] = customValidator.sourceInputRefs();
      const enabled                = customValidator.enabled()

      const valid = !enabled || _.isUndefined(errorMessage);
      if (!valid) {
        if(fieldName) {
          if(_.isString(fieldName)) {
            invalidFields.add(fieldName);
          } else {
            fieldName.forEach(f => invalidFields.add(f));
          }
        }

        isValid = false;

        if (sourceInputRefs.length === 0) {
          throw new WarnError(errorMessage);
        } else {
          for (const $input of sourceInputRefs) {
            $input.$emit(FORM_INPUT_VALIDATION_ERROR_EVENT_NAME, errorMessage);
            stack($input);
          }
        }
      } else if (!fieldName || _.isString(fieldName) && !invalidFields.has(fieldName)
          || _.isArray(fieldName) && _.some([...fieldName.values()], f => invalidFields.has(f))) {
        if (sourceInputRefs.length > 0) {
          for (const $input of sourceInputRefs) {
            $input.$emit(FORM_INPUT_CLEAR_VALIDATION_ERRORS_EVENT_NAME);
          }
        }
      }
    }

    // scroll
    if (scrollToInput) {
      // @ts-ignore
      (scrollToInput.$el as HTMLElement).scrollIntoView()
    }

    return isValid;


    function getElemByType<T>(el: HTMLElement, type: Ctor<T>): T | undefined {
      if (el instanceof type) {
        return el;
      }
      const children = el.querySelectorAll('*');
      for(let i = 0; i < children.length; i++) {
        const child = children[i];
        if(child instanceof type) {
          return child;
        }
      }
      return undefined;
    }
  }

  /**
   * Do not override this method
   */

  /* FINAL */
  async submit() {
    if (!this.doValidateForm(castToVue(this.$refs.vFormRoot)!, this.formData)) {
      return;
    }

    try {
      this.loading = true;
      const ok     = await this.send();
      if (ok) {
        this.resetData();
      }
    } finally {
      this.loading = false;
    }
  }

  async send(): Promise<boolean | never> {
    throw new Error('Abstract method send must be implemented in base class: ' + this.constructor.name)
  }

  protected _cloneBaseData(): void {
    this._baseData = _.cloneDeep(this.$data);
  }
}
