import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { is_running_in_pwa } from "~/lib/util/util";

export const name = "IsPwaInstallable";

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class IsPwaInstallable extends VuexModule {
  install_prompt: any = null;

  @Mutation
  setInstallPrompt(install_prompt: any): void {
    this.install_prompt = install_prompt;
  }
}

export function start_monitoring_beforeinstallprompt(store: IsPwaInstallable) {
  function listener(event: any) {
    // event.preventDefault();
    console.log("IsPwaInstallable :: got beforeinstallprompt event:", event);
    store.setInstallPrompt(event);
  }

  window.addEventListener("beforeinstallprompt", listener);
}

function on_is_running_in_pwa_change(callback: (is_pwa: boolean) => void) {
  const m = window.matchMedia("(display-mode: standalone)");

  const listener = (event: MediaQueryListEvent) => {
    console.log("on_is_running_in_pwa_change ->", event.matches);
    callback(event.matches);
  };

  m.addEventListener("change", listener);

  const remove = () => {
    m.removeEventListener("change", listener);
  };

  return remove;
}
