import _ from 'lodash';
import {DateTime} from 'luxon';
import Vue from 'vue';

// Vue instance
declare module 'vue/types/vue' {
  interface Vue {
    $formatMoney: (x: number | string) => string
    $formatDate: (x: string) => string
  }
}

Vue.prototype.$formatMoney = function $formatMoney(x: number | string): string {
  if(_.isString(x)) {
    x = parseFloat(x);
  }
  return x ? `${x.toFixed(2).replace('.', ',')} zł` : '0,00 zł';
}

Vue.prototype.$formatDate = function formatDate(isoString: string): string {
  return DateTime.fromISO(isoString).toFormat("yyyy-MM-dd HH:mm");
}
