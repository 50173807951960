
import {Component, Prop} from 'nuxt-property-decorator';
import Vue from 'vue';
import ErrorCatcher from '~/components/ErrorCatcher.vue';


@Component({
  components: {ErrorCatcher},
})
export default class PageSection extends Vue {
  constructor() {
    super();
  }

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  innerClass!: string

}
