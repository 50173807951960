
import Vue from 'vue';
import {commonService} from '~/lib/accessors/api';

export default Vue.extend({
  methods: {
    async stopImpersonation() {
      await commonService.withContext(this).stopImpersonation()

      // We redirect to the main page, so we don't end up on a page that is supposed
      // to work only for impersonated users (e.g. zalogowany-opiekun). We do it
      // with full page reload to make sure new authentication information is
      // loaded.
      const l = window.location;
      window.location.href = `${l.protocol}//${l.host}`;
    }
  }
})
