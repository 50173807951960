var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{ref:"topBar",staticClass:"top-bar",class:{'top-bar--mobile-menu-visible': _vm.mobileMenuVisible,
           'top-bar--any-menu-dropdown-visible': _vm.anyDropdownVisible}},[_c('div',{staticClass:"container top-bar__inner"},[_c('AppLink',{staticClass:"py-1",attrs:{"to":{ name: 'index' }}},[_c('img',{staticClass:"top-bar__logo h-full",attrs:{"fit":"cover","src":"/images/kiplogo.webp"}})]),_vm._v(" "),_c('nav',{staticClass:"top-bar__nav",attrs:{"role":"navigation"}},[_c('MenuDropdown',{staticClass:"top-bar__dropdown",class:{ 'top-bar__dropdown--visible': _vm.menuDropdownState[0] },attrs:{"menu-dropdown-visible":_vm.menuDropdownState[0],"menu-button":{ displayName: 'Dla pomagających', rootName: 'index' },"menu-items":[
          { displayName: 'Jak pomagać?', to: {name: 'index', hash: '#jak-to-dziala'} },
          { displayName: 'Podopieczni', to: {name: 'podopieczni'} },
          { displayName: 'Dodaj rozszerzenie', to: {name: 'rozszerzenie'} },
          { displayName: 'Sklepy', to: {name: 'sklepy'} },
          { displayName: 'Pytania i odpowiedzi', to: {name: 'pomoc'} },
        ]},on:{"navigation":_vm.closeMobileMenu,"update:menuDropdownVisible":function($event){return _vm.$set(_vm.menuDropdownState, 0, $event)},"update:menu-dropdown-visible":function($event){return _vm.$set(_vm.menuDropdownState, 0, $event)}}}),_vm._v(" "),_c('MenuDropdown',{staticClass:"top-bar__dropdown",class:{ 'top-bar__dropdown--visible': _vm.menuDropdownState[1] },attrs:{"menu-dropdown-visible":_vm.menuDropdownState[1],"menu-button":{ displayName: 'Dla potrzebujących', rootName: 'dla-potrzebujacych' },"menu-items":[
          { displayName: 'Dołącz do nas', to: {name: 'dla-potrzebujacych'} },
          { displayName: 'Sklepy', to: {name: 'sklepy'} },
          { displayName: 'Dodaj rozszerzenie', to: {name: 'rozszerzenie'} },
          { displayName: 'Pytania i odpowiedzi', to: {name: 'pomoc'} },
        ]},on:{"navigation":_vm.closeMobileMenu,"update:menuDropdownVisible":function($event){return _vm.$set(_vm.menuDropdownState, 1, $event)},"update:menu-dropdown-visible":function($event){return _vm.$set(_vm.menuDropdownState, 1, $event)}}}),_vm._v(" "),(_vm.isLoggedIn && _vm.mobileMenuVisible)?_c('MenuDropdown',{staticClass:"top-bar__dropdown top-bar__your-account-dropdown",class:{ 'top-bar__dropdown--visible': _vm.menuDropdownState[2] },attrs:{"root-text":"Twoje konto","menu-dropdown-visible":_vm.menuDropdownState[2],"menu-button":{
          ..._vm.omit(_vm.yourAccountButton, 'to'),
          displayName: 'Twoje konto',
          class: 'your-account-menu-button',
          style: '',
        },"menu-items":_vm.loggedInMenu},on:{"navigation":_vm.closeMobileMenu,"update:menuDropdownVisible":function($event){return _vm.$set(_vm.menuDropdownState, 2, $event)},"update:menu-dropdown-visible":function($event){return _vm.$set(_vm.menuDropdownState, 2, $event)}}}):_vm._e(),_vm._v(" "),_c('app-link',{staticClass:"top-bar__btn top-bar__blog-btn btn-empty-white",attrs:{"to":{ name: 'blog' }},nativeOn:{"click":function($event){return _vm.closeMobileMenu.apply(null, arguments)}}},[_vm._v("Blog")]),_vm._v(" "),(_vm.mobileMenuVisible)?_c('mobile-menu-contact-info'):_vm._e(),_vm._v(" "),(_vm.mobileMenuVisible)?_c('div',{staticClass:"top-bar__nav-filler"}):_vm._e(),_vm._v(" "),(_vm.mobileMenuVisible)?_c('mobile-menu-social-shares'):_vm._e(),_vm._v(" "),(! _vm.isLoggedIn)?_c('app-link',{staticClass:"top-bar__login-btn btn-outline-white",attrs:{"to":{ name: 'zaloguj' }},nativeOn:{"click":function($event){return _vm.closeMobileMenu.apply(null, arguments)}}},[_vm._v("Zaloguj się")]):(! _vm.mobileMenuVisible)?_c('MenuDropdown',{staticClass:"top-bar__dropdown top-bar__your-account-dropdown",class:{ 'top-bar__dropdown--visible': _vm.menuDropdownState[2] },attrs:{"root-text":"Twoje konto","menu-dropdown-visible":_vm.menuDropdownState[2],"menu-button":{
          ..._vm.omit(_vm.yourAccountButton, 'to'),
          displayName: 'Twoje konto',
          component: 'IconWithAlert',
          class: 'your-account-menu-button',
          style: '',
          props: {
            alert: !_vm.isProfileComplete,
            alertPosition: 'far-right'
          }
        },"menu-items":_vm.loggedInMenu},on:{"navigation":_vm.closeMobileMenu,"update:menuDropdownVisible":function($event){return _vm.$set(_vm.menuDropdownState, 2, $event)},"update:menu-dropdown-visible":function($event){return _vm.$set(_vm.menuDropdownState, 2, $event)}}}):_vm._e(),_vm._v(" "),(! _vm.isLoggedIn && _vm.mobileMenuVisible)?_c('div',{staticClass:"top-bar__register-btn-wrapper"},[_c('button',{staticClass:"top-bar__btn top-bar__register-btn",on:{"click":function($event){return _vm.$refs.contextMenu.$emit('open', $event)}}},[_vm._v("\n          Zarejestruj się\n        ")]),_vm._v(" "),_c('PrettyContextMenu',{ref:"contextMenu",staticClass:"w-0 mx-auto",attrs:{"position":"left","menuItems":[{
            id: 1,
            text: 'Chcę pomagać',
            action: () => {
              _vm.$refs.contextMenu.$emit('close');
              _vm.closeMobileMenu();
              _vm.$router.push({name: 'podopieczni', query: _vm.chcePomagacQuery});
            },
          }, {
            id: 2,
            text: 'Potrzebuję pomocy',
            action: () => {
              _vm.$refs.contextMenu.$emit('close');
              _vm.closeMobileMenu();
              _vm.$router.push({name: 'dodaj-podopiecznego'});
            }
          }]}})],1):_vm._e()],1),_vm._v(" "),_c('nav',{staticClass:"top-bar__mobile-nav"},[_c('div',{staticClass:"hamburger__outer"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileMenuVisible),expression:"!mobileMenuVisible"}],staticClass:"p-1 px-3",on:{"click":_vm.toggleMobileMenu}},[_c('icon-with-alert',{attrs:{"alert":_vm.menuAlertVisible,"size":"28px"}},[_c('HamburgerMenuIcon',{staticClass:"menu-icon hamburger-menu"})],1)],1),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileMenuVisible),expression:"mobileMenuVisible"}],staticClass:"p-1 px-3",on:{"click":_vm.toggleMobileMenu}},[_c('CloseMenuIcon',{staticClass:"menu-icon close-menu"})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }