import {AxiosResponse} from 'axios';


export class ResponseError extends Error {
  readonly status: number

  constructor(msg: string, details: any) {
    super(msg);
    this.status = details.status;
  }
}

export class ErrorResponse {
    readonly status: number
    readonly statusText: string
    readonly body: any
    readonly requestUrl: string

    constructor(resp: AxiosResponse, requestUrl: string) {
        this.status = resp.status;
        this.statusText = resp.statusText;
        this.body = resp.data;
        this.requestUrl = requestUrl;
    }

    toError(): ResponseError {
      return new ResponseError(this.toMessage(), {
        status: this.status,
      });
    }

    toMessage(): string {
      return `Response error: ${this.status} ${this.statusText}: ${JSON.stringify(this.body, undefined, 2)}`;
    }

    consoleWarn() {
      console.warn(this.toMessage());
    }

    consoleError() {
      console.error(this.toMessage());
    }
}
