
import Vue from 'vue';


export default Vue.extend({
  methods: {
    async logout() {
      await this.$auth.logout()
      await this.$router.push({name: 'index'});
    }
  }
})
