export interface TransactionsAggregate {
    paid_to_child: number
    paid_to_publisher_only: number
    accepted: number
    pending: number
    num_of_helpers: number
}

export function emptyTransactionsAggregate(): TransactionsAggregate {
  return {
    paid_to_child: 0,
    paid_to_publisher_only: 0,
    accepted: 0,
    pending: 0,
    num_of_helpers: 0
  };
}
