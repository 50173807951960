import {Component} from 'nuxt-property-decorator';
import Vue from 'vue';
import {appendErrorId, attachErrorId} from '~/lib/api/types/errors/Errors';
import {ERROR_EVENT_NAME} from '~/plugins/mixin-api-error-handlers';
import ErrorBroker from '~/store/ErrorBroker';


@Component
export default class ErrorHandlingComponent extends Vue {
  _listener: any
  channelName: string | undefined

  mounted() {
    const name     = this.constructor.name;
    if(! this.channelName) {
      console.warn('No _channelName at this component: '+name+', not registering any listeners');
      return;
    }

    /**
     * We need to rethrow error into vue context by sending it to vue event listener.
     * Otherwise we will get Uncaught (Promise) Error which is nowhere to be handled,
     * besiedes unhandledrejection window event listener.
     */
    ErrorBroker.INSTANCE.on(this.channelName, this._listener = (err: Error) => {
      // send from here ...
      this.$emit('my-error', err);
    })

    // ... to here
    this.$on('my-error', (err: Error) => {
      this._handleError(err);
    });
  }

  beforeDestroy() {
    if(this._listener) {
      ErrorBroker.INSTANCE.off('page_error', this._listener);
    }
  }

  private _handleError(err: Error) {
    const name     = this.constructor.name;
    const errorId = attachErrorId(err);
    // this.warn = err.message;
    console.log(appendErrorId(`<${name}/> got error`, errorId));

    if (this.$refs.errorCatcher) {
      (this.$refs.errorCatcher! as Vue).$emit(ERROR_EVENT_NAME, err);
    } else {
      console.log(appendErrorId(`<${name}/> No errorCatcher available, rethrowing`, errorId));
      throw err;
    }
  }
}
