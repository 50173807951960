import {Store} from 'vuex'
import {getModule} from 'vuex-module-decorators'
import ChildGetSingle from '~/store/ChildGetSingle';
import ChildProfileStore from '~/store/ChildProfileStore';
import ChildSearch from '~/store/ChildSearch';
import FilledDocumentsStore from '~/store/FilledDocumentsStore';
import GlobalStore from '~/store/GlobalStore';
import ShopsStore from '~/store/ShopsStore';
import TransactionsStore from '~/store/TransactionsStore';
import TransactionInfoPopupStore from '~/store/TransactionInfoPopupStore';
import IsPwaStore, {start_monitoring_is_pwa} from '~/store/IsPwaStore';
import IsPwaInstallable, { start_monitoring_beforeinstallprompt } from '~/store/IsPwaInstallable';
import ConnectivityStore, { start_monitoring_connectivity } from '~/store/ConnectivityStore';


let childSearchStore: ChildSearch
let childGetSingleStore: ChildGetSingle
let childProfileStore: ChildProfileStore
let shopsStore: ShopsStore
let transactionsStore: TransactionsStore
let filledDocumentsStore: FilledDocumentsStore
let globalStore: GlobalStore
let transactionInfoPopupStore: TransactionInfoPopupStore
let isPwaStore: IsPwaStore
let isPwaInstallable: IsPwaInstallable
let connectivityStore: ConnectivityStore


function initialiseStores(store: Store<any>): void {
  childSearchStore = getModule(ChildSearch, store)
  childGetSingleStore = getModule(ChildGetSingle, store)
  shopsStore = getModule(ShopsStore, store)
  transactionsStore = getModule(TransactionsStore, store)
  filledDocumentsStore = getModule(FilledDocumentsStore, store);
  childProfileStore = getModule(ChildProfileStore, store)
  globalStore = getModule(GlobalStore, store)
  transactionInfoPopupStore = getModule(TransactionInfoPopupStore, store)
  isPwaStore = getModule(IsPwaStore, store);
  isPwaInstallable = getModule(IsPwaInstallable, store);
  connectivityStore = getModule(ConnectivityStore, store);

  start_monitoring_is_pwa(isPwaStore);
  start_monitoring_beforeinstallprompt(isPwaInstallable);
  start_monitoring_connectivity(connectivityStore);
}


export {
  initialiseStores,
  childSearchStore,
  childGetSingleStore,
  childProfileStore,
  shopsStore,
  transactionsStore,
  filledDocumentsStore,
  transactionInfoPopupStore,
  globalStore,
  isPwaStore,
  isPwaInstallable,
  connectivityStore,
}
