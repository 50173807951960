import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {childService} from '~/lib/accessors/api';
import ChildView, {childViewEmpty} from '~/lib/types/ChildView';
import appConfig from '~/lib/util/appConfig';
import {fakeChild, toChildView} from '~/store/ChildSearch';
import ErrorBroker from '~/store/ErrorBroker';
// import {store} from "~/store/index";


export const name = 'ChildGetSingle';

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class ChildGetSingle extends VuexModule {
  instance: ChildView = childViewEmpty()

  @Mutation
  setChild(child: ChildView): void {
    this.instance = child;
  }

  @Mutation
  purge(): void {
    this.instance = childViewEmpty();
  }

  @Action
  async fetchSingleById(id: number) {
    try {
      if(appConfig.env === 'mock') {
        this.setChild(fakeChild());
      } else {
        const child: Child = await childService
          .withErrorBroker()
          .getDetail(id)
        this.setChild(toChildView(child));
      }
    } catch(err) {
      ErrorBroker.CatchError(err)
    }
  }

  @Action
  async fetchSingleBySlug(slug: string) {
    try {
      if(appConfig.env === 'mock') {
        this.setChild(fakeChild());
      } else {
        const child: Child = await childService
          .withErrorBroker()
          .getChildBySlug(slug)
        this.setChild(toChildView(child));
      }
    } catch(err) {
      ErrorBroker.CatchError(err)
    }
  }

  get child(): ChildView {
    return this.instance;
  }
}
