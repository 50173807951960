import _ from 'lodash';
import Vue from 'vue';
import ErrorCatcher from '~/components/ErrorCatcher.vue';
import {attachErrorId, WarnError, WithEmitToChilder} from '~/lib/api/types/errors/Errors';
import {
  emitErrorToChildErrorCatcher,
  emitErrorToParentErrorCatcher,
  findErrorCatchersInChildrenTree,
} from '~/lib/error-handling/finder_and_emitters';
import {loggerErrorIdFactory} from '~/lib/util/util';


declare module 'vue/types/vue' {
  interface Vue {
    notifications: {
      success: string
      warn: string
      error: string
    },
    $warn: (msg: string) => false
    $success: (msg: string, timeout?: number) => true
    $validate: (value: any, msg: string) => boolean
    $clearAllChildErrorCatchers: () => void
  }
}

Vue.mixin({
  data() {
    return {
      notifications: {
        success: '',
        warn: '',
      }
    };
  },
});

Vue.prototype.$warn = function(msg: string): false {
  if(this.$refs.errorCatcher) {
    this.$refs.errorCatcher.$emit(WARN_EVENT_NAME, msg);
  } else {
    this.notifications.warn = msg;
    throw new WarnError(msg);
  }
  return false
}

Vue.prototype.$success = function(msg: string, timeout?: number): true {
  this.notifications.success = msg;
  if(timeout) {
    setTimeout(() => {
      this.notifications.success = '';
    }, timeout);
  }
  return true;
}

Vue.prototype.$validate = function(value: any, msg: string) {
  if(! value) {
    this.$warn(msg);
    return false;
  } else {
    return true;
  }
}

Vue.prototype.$clearAllChildErrorCatchers = function() {
  const errorCatchers = findErrorCatchersInChildrenTree(this);
  for(const errorCatcher of errorCatchers) {
    errorCatcher.$emit(CLEANUP_EVENT_NAME)
  }
}

export const CLEANUP_EVENT_NAME = 'my-cleanup';
export const ERROR_EVENT_NAME = 'my-error';
export const WARN_EVENT_NAME = 'my-warn';
export const RETHROW_IN_VUE_CONTEXT_EVENT_NAME = 'rethrow-in-vue-context';

// const {log, logWarn, logError} = loggerErrorIdFactory('Vue.errorHandler');
const {loggersWithErrorId} = loggerErrorIdFactory('Vue.errorHandler');

Vue.config.errorHandler = function(err: Error, vue: Vue, info: string): boolean | void {
  const STOP_PROPAGATION = true;
  const ESCALATE_ERROR = false;
  const {log, logWarn, logError} = loggersWithErrorId(attachErrorId(err));
  if(! vue) {
    // logWarn('escalate error (no vue instance)');
    // throw err;
    logError('FATAL (no vue instance): error:', err);
    return ESCALATE_ERROR;
  }
  log('got error at $el=', vue.$el);

  if(vue instanceof ErrorCatcher) {
    return ESCALATE_ERROR;
  }

  // let emitter: typeof emitErrorToParentErrorCatcher;
  let emitError
  let emitToChildren = (err as WithEmitToChilder).emitToChildren
  let _emitToChildren: Vue | undefined;
  if(emitToChildren && (_emitToChildren = emitToChildren())) {
    log('try emit to child', _emitToChildren);
    emitError = () => emitErrorToChildErrorCatcher(err, _emitToChildren!);
  } else {
    log('try emit to parents of', vue);
    // debugger;
    emitError = () => emitErrorToParentErrorCatcher(err, vue);
  }

  if(! emitError()) {
    logWarn('escalate error', err);
    // logError('err', err);
    return ESCALATE_ERROR;
  } else {
    log('handled');
    return STOP_PROPAGATION;
  }
}

