import Vue from 'vue';
import {BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {
  BaseServiceWithContext,
  createBaseServiceWithContext,
  IBaseServiceWithContext,
  validateEmitErrorsTo,
  XVue,
} from '~/lib/api/base/BaseServiceWithContext';
import {assert} from '~/lib/util/util';
import ErrorBroker from '~/store/ErrorBroker';


export type Options = {
  emitErrorsTo?: Vue | Element | (Vue | Element)[] | undefined
  onBeforeThrowError?: () => void
}


export class BaseService<TClient extends BaseApiClient, TContext extends BaseServiceWithContext<TClient>> {
  constructor(
    protected apiClient: TClient,
    protected constr: IBaseServiceWithContext<TClient, TContext>,
    protected errorBroker?: ErrorBroker,
  ) {
  }

  withContext(vm: XVue, options: Options = {}): TContext {
    return createBaseServiceWithContext(this.constr,
      this.apiClient,
      vm,
      options.onBeforeThrowError || (() => {}),
      validateEmitErrorsTo(options.emitErrorsTo),
    )
  }

  withErrorBroker(options: Pick<Options, 'onBeforeThrowError'> = {}) {
    assert(this.errorBroker, 'errorBroker must be provided');
    // super(apiClient, null, ChildServiceWithContext);
    return createBaseServiceWithContext(this.constr,
      this.apiClient,
      this.errorBroker,
      options.onBeforeThrowError || (() => {}),
      undefined
    )
  }
}