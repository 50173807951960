
import {Component, Prop, PropSync, Watch} from 'nuxt-property-decorator';
import Vue from 'vue';


@Component
export default class Success extends Vue {
  @PropSync('success',{
    type: String,
    required: false,
  })
  successSync!: string | null

  @Prop({
    type: String,
    required: false,
  })
  title!: string

  @Prop({
    type: Boolean,
    required: false,
  })
  closable!: boolean | null

  isClosed = false

  get show(): boolean {
    return !this.isClosed && (!!this.successSync || (this.$slots['default']?.length || 0) > 0);
  }

  close() {
    this.isClosed = true
    this.successSync = null
  }

  @Watch('success')
  watchSuccess(newValue: string | null) {
    if(newValue) {
      this.isClosed = false;
    }
  }
}
