import {IdentifiableItemWithImg} from '~/lib/types/IdentifiableItemWithImg';
import {WithNuxtLink} from '~/lib/types/WithNuxtLink';


export default interface ChildView extends WithNuxtLink {
  id: number
  slug: string
  fullName: string
  photoUrl: string
  photoThumbUrl: string
  photoGallery: GalleryPhoto[]
  hasPhoto: boolean
  description: string
}

export const ChildViewEmpty: ChildView = {
  id: 0,
  slug: '',
  fullName: '',
  photoUrl: '',
  photoThumbUrl: '',
  photoGallery: [],
  hasPhoto: false,
  description: '', nuxtLink: {to: {name: 'index'}}
};

export function childViewEmpty(): ChildView {
  return {
    id: 0,
    slug: '',
    fullName: '',
    photoUrl: '',
    hasPhoto: false,
    photoThumbUrl: '',
    photoGallery: [],
    description: '', nuxtLink: {to: {name: 'index'}}
  };
}

export function toChildrenAvatarLogo(child: ChildView): IdentifiableItemWithImg {
  return {
    id: child.id,
    imgSrc: child.photoThumbUrl,
    imgAlt: `Zdjęcie podopiecznego ${child.fullName}`,
    nuxtLink: {
      to: {
        name: 'podopieczny-slug',
        params: {
          slug: child.slug
        }
      }
    }
  }
}
