import {Context} from '@nuxt/types';
import appConfig from '~/lib/util/appConfig';


export default function({ $auth, $user }: Context) {
 $auth.onRedirect((to: string, from: string) => {
  if($auth.loggedIn && from === '/zaloguj' && to === appConfig.auth.homeRoute) {
    const user = $user();
    switch(user.role) {
      case "parent": return '/zalogowany-opiekun';
      case "helper": return '/zalogowany-pomagajacy';
      default: return "/";
    }
  } else {
    return to;
  }
 });
}
