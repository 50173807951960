import axios, {AxiosError} from 'axios';
import _ from 'lodash';
import Vue from 'vue';
import {BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {ErrorResponse} from '~/lib/api/types/errors/ErrorResponse';
import {
  BackendUserError,
  ClientTimeoutError,
  FatalError,
  NetworkError,
  NuxtErrorImpl,
  WarnError,
  WithEmitToChilder,
  WithErrorBroker,
} from '~/lib/api/types/errors/Errors';
import {ServiceReq} from '~/lib/api/types/types';
import {handleDjangoBadRequest, extractDjangoErrorMessage} from '~/lib/util/api_utils';
import {assert, AssertionError} from '~/lib/util/util';
import {SERVICE_CHANNEL_NAMES} from '~/store/constants';
import ErrorBroker from '~/store/ErrorBroker';


export type EmitErrorsTo = Vue
export type XVue = Pick<Vue, '$auth' | '$warn' | '$success' | '$clearAllChildErrorCatchers'>

export function validateEmitErrorsTo(emitErrorsTo: Vue | Element | (Vue | Element)[] | undefined): EmitErrorsTo | undefined {
  assert(_.isUndefined(emitErrorsTo) || emitErrorsTo instanceof Vue,
    'wireErrorsTo must be undefined or instanceof Vue');

  return emitErrorsTo as EmitErrorsTo | undefined;
}

/**
 * Constructor types
 * -----------------
 *
 * TL;DR constructor types defined in type/interface are available during type checking phase, but class definition
 * objects are available during runtime (this is not exactly correct but you know what I mean). 'typeof' is available
 * during this second phase.
 *
 * Because of this it seems to be impossible to create generic type constructor and use it. But it is possible to clone
 * constructor parameters of your class and create interface with constructor signature and then use it. For example it
 * is possible to create constructor interface C and use it without doing 'class X implements C' on the base class.
 * These two will work without adding 'class X implements C':
 *   1. function f(c: C, params: ConstructorParameters<C>): X { new c(...params); };
 *      const x: X = f(X, param1, param2, ...);
 *
 *   2. class Y extends X { ... no constructor overloading ... };
 *      function f<Y extends X>(c: C, params: ConstructorParameters<C>): Y { new c(...params); };
 *      const y: Y = f(Y, param1, param2, ...); // params for X base class constructor
 *
 *
 * Sources:
 *
 * "The simplest part to answer is what is the difference between typeof A, new () => A and {new (): A}. The last two
 * are equivalent, the { new() : A } syntax is the more verbose cousin of new () => A. The reason to use the former
 * more verbose version is because is allows you to specify more overloads for the constructor, and would also allow
 * you to specify extra members (ie static methods). typeof A is the class A which includes the constructor signature,
 * as well as any statics. If you just care about being able to create instances of the class, the simple constructor
 * signature is good enough. If you need to access the statics as well you need typeof Class."
 *
 *   - https://stackoverflow.com/a/52355696/1637178
 *
 *
 * "When working with classes and interfaces, it helps to keep in mind that a class has two types: the type of the
 * static side and the type of the instance side. You may notice that if you create an interface with a construct
 * signature and try to create a class that implements this interface you get an error
 *
 * This is because when a class implements an interface, only the instance side of the class is checked. Since the
 * constructor sits in the static side, it is not included in this check."
 *
 *   -
 * https://www.typescriptlang.org/docs/handbook/interfaces.html#difference-between-the-static-and-instance-sides-of-classes
 */

export interface IBaseServiceWithContext<TClient extends BaseApiClient, TContext extends BaseServiceWithContext<TClient>> {
  new(
    apiClient: TClient,
    context: XVue | ErrorBroker | any,
    onBeforeThrowError: () => void,
    wireErrorsTo?: EmitErrorsTo,
  ): TContext
}


export function createBaseServiceWithContext<TClient extends BaseApiClient, TContext extends BaseServiceWithContext<TClient>>(
  ctor: IBaseServiceWithContext<TClient, TContext>,
  ...params: ConstructorParameters<IBaseServiceWithContext<TClient, TContext>>
): TContext {
  return new ctor(...params)
}


export class BaseServiceWithContext<TClient extends BaseApiClient> {
  protected vm: XVue | undefined;
  protected errorBroker: ErrorBroker | undefined;

  constructor(
    protected apiClient: TClient,
    context: XVue | ErrorBroker | any,
    protected onBeforeThrowError: () => void = (() => {}),
    protected emitErrorsTo?: EmitErrorsTo,
  ) {
    if(context instanceof Vue) {
      this.vm = context as Vue;
    } else if(context instanceof ErrorBroker) {
      this.errorBroker = context
    } else {
      throw new AssertionError("context must be of type Vue | ErrorBroker, got: "+context);
    }
  }

  protected getBaseChannelName(): string {
    const channelName = SERVICE_CHANNEL_NAMES.get(this.constructor);
    assert(channelName, `channelName must be present in SERVICE_CHANNEL_NAMES, this.constructor=`+this.constructor.name)
    return channelName!;
  }

  private _handleAxiosError<T>(err: AxiosError<any>, options: Omit<ServiceReq<T>, 'callback'>): never {
    if(err.response) {
      return this._handleApiResponse(new ErrorResponse(err.response!, err.request?.url), options, err.request as any);
    } else if(err.message.toLowerCase() === 'network error') {
      throw new NetworkError(err);
    } else if(err.code === 'ECONNABORTED' && err.message?.includes('timeout')) {
      throw new ClientTimeoutError(err);
    } else {
      throw new FatalError('Axios error', err);
    }
  }

  private _handleApiResponse<T>(resp: ErrorResponse, options: Omit<ServiceReq<any>, 'callback'>, axiosRequest?: any): never {
    const h = options.errorHandlers[resp.status];
    let err: Error
    if (h) {
      resp.consoleWarn();
      const msg = h(resp);
      console.warn(msg);
      err = new WarnError(msg);
    } else if (resp.status === 401) {
      resp.consoleWarn();
      err = new WarnError('Sesja wygasła, zaloguj się jeszcze raz.');
    } else if (resp.status === 400) {
      resp.consoleWarn();
      err = new WarnError(handleDjangoBadRequest(resp));
    } else if (resp.status === 404) {
      resp.consoleWarn();
      err = new NuxtErrorImpl(handleDjangoBadRequest(resp), resp.requestUrl, 404);
    } else if (resp.status >= 400 && resp.status < 500) {
      resp.consoleWarn();
      err = new BackendUserError(extractDjangoErrorMessage(resp), resp.status);
    } else {
      // TODO wire sentry errors
      err = new FatalError('Błąd serwera', resp)
      // console.error(err);
    }

    if (this.emitErrorsTo) {
      (err as WithEmitToChilder).emitToChildren = () => this.emitErrorsTo;
    }

    if (this.errorBroker) {
      // (err as WithErrorBroker).errorBroker = this.errorBroker;
      (err as WithErrorBroker).errorChannel = options.errorChannel;
    }
    throw err;
  };

  private _handleWarnError<T>(err: WarnError): never {
    assert(this.vm, '_handleWarnError must be used only in vm context, but no vm is provided');
    if (this.emitErrorsTo) {
      (err as WithEmitToChilder).emitToChildren = () => this.emitErrorsTo;
    }
    throw err;
  }

  protected async exec<T>(req: ServiceReq<T>): Promise<T> {
    try {
      const ret: T | ErrorResponse = await req.callback()

      if (ret instanceof ErrorResponse) {
        this.onBeforeThrowError();
        this._handleApiResponse(ret, _.omit(req, 'callback'));
      } else {
        return req.map ? req.map(ret) : ret;
      }
    } catch (err) {
      this.onBeforeThrowError();
      if (axios.isAxiosError(err)) {
        this._handleAxiosError(err, _.omit(req, 'callback'));
      } else if(err instanceof WarnError) {
        this._handleWarnError(err);
      } else {
        throw err;
      }
    }
  }
}
