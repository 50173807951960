
import Vue, {PropOptions} from 'vue'
export default Vue.extend({
  props: {
    alert: {
      type: Boolean,
      default: false
    },
    alertPosition: {
      type: String,
      default: 'left'
    } as PropOptions<'left' | 'right' | 'far-right'>,
    size: {
      type: String,
      required: false,
      default: undefined
    }
  }
})
