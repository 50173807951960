
import Vue, {PropOptions} from 'vue';

export default Vue.extend({
  props: {
    warn: {
      type: String,
      required: false,
      default: ''
    } as PropOptions<string | null>,
    forceShow: {
      type: Boolean,
      required: false,
      default: false
    } as PropOptions<boolean | null>,
  },
  computed: {
    show(): boolean {
      return this.forceShow || !!this.warn || (this.$slots['default']?.length || 0) > 0;
    }
  }
})
