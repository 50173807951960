
import DaneFundacji from "./util/Content";
import window_size_aware_mixin from "../lib/util/window_size_aware_mixin";

export default {
  name: "Footer",
  mixins: [window_size_aware_mixin],
  components: {DaneFundacji},
  data() {
    return {
      pages: [],
    }
  },
  async created() {
    this.pages = await this.$content('footer')
      .sortBy('slug', 'asc')
      .fetch()
  }
}
