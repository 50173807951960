import {Transaction} from '~/lib/api/types/Transaction';


export type SortDirection = 'asc' | 'desc';

export interface Field {
  name: keyof Transaction
  label: string
  sortDirection?: SortDirection
  cssClass: string
}

export function reverseSort(direction: SortDirection): SortDirection {
  return direction === 'asc' ? 'desc' : 'asc';
}

export function defaultSortDirection(): SortDirection {
  return 'desc';
}
