
import { connectivityStore, isPwaStore } from '~/lib/accessors/store-accessor';

export default {
  name: 'ConnectivityBar',
  computed: {
    is_online() {
      return connectivityStore.is_connected;
    },
    conn_state() {
      return connectivityStore.conn_state;
    },
    msg() {
      switch(this.conn_state) {
        case 'disconnected':
          return 'Brak połączenia z internetem - aplikacja może nie działać poprawnie';
        case 'error':
          return 'Brak możliwości połączenia - odśwież stronę';
        case 'connecting':
          return 'Próba ponownego połączenia...';
        default:
          return '';
      }
    },
    is_pwa() {
      return isPwaStore.is_pwa;
    },
  }
}
