import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
// import {store} from "~/store/index";


export const name = 'ChildProfileStore';

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class ChildProfileStore extends VuexModule {
  showCompleteYourProfileMenu: boolean = false;
  profilePhotoInputOpened: boolean = false;

  @Mutation
  openCompleteYourProfileMenu(): void {
    this.showCompleteYourProfileMenu = true;
  }

  @Mutation
  closeCompleteYourProfileMenu(): void {
    this.showCompleteYourProfileMenu = false;
  }

  @Mutation
  setCompleteYourProfileMenu(value: boolean): void {
    this.showCompleteYourProfileMenu = value;
  }

  @Action
  async openProfilePhotoInput() {
    this.setProfilePhotoInputOpened(true);
    this.closeCompleteYourProfileMenu();
    // this.context.commit('setProfilePhotoInputOpened', true);
    // this.context.commit('closeCompleteYourProfileMenu');

    // We use profilePhotoInputOpened to notify the UI that the input is open and we don't have
    // native input close event, so we set it to false immediately, so next opening of profile photo
    // input is tracked correctly.
    await new Promise((resolve) => setTimeout(resolve, 50));
    // this.context.commit('setProfilePhotoInputOpened', false);
    this.setProfilePhotoInputOpened(false);
    // this.setProfilePhotoInputOpened(true);
    // this.closeCompleteYourProfileMenu();
  }

  @Mutation
  closeProfilePhotoInput(): void {
    this.profilePhotoInputOpened = false;
  }

  @Mutation
  setProfilePhotoInputOpened(value: boolean): void {
    // this.profilePhotoInputOpened = value;
    if (value) {
      // this.openCompleteYourProfileMenu();
      this.profilePhotoInputOpened = true;
    } else {
      this.profilePhotoInputOpened = false;
      // this.closeCompleteYourProfileMenu();
    }
  }
}
