import _ from 'lodash';
import Vue from 'vue';
import ErrorCatcher from '~/components/ErrorCatcher.vue';
import {attachErrorId} from '~/lib/api/types/errors/Errors';
import {assert, loggerErrorIdFactory} from '~/lib/util/util';
import {ERROR_EVENT_NAME} from '~/plugins/mixin-api-error-handlers';

const {log, logWarn, logError} = loggerErrorIdFactory('error-handling.finders_and_emitters');


/**
 *
 * @param err
 * @param vm
 * @return true if successfully found and emitted error, false otherwise
 */
export function emitErrorToParentErrorCatcher(err: Error, vm: Vue): boolean {
  const errorCatcher = findErrorCatcherInParentTree(vm);

  if(errorCatcher) {
    log(attachErrorId(err), 'errorCatcher.$emit [to parent]', err.message, 'errorCatcher=', errorCatcher);
    errorCatcher.$emit(ERROR_EVENT_NAME, err);
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param err
 * @param vm
 * @return true if successfully found and emitted error, false otherwise
 */
export function emitErrorToChildErrorCatcher(err: Error, vm: Vue): boolean {
  const errorCatchers = findErrorCatchersInChildrenTree(vm);
  const errorCatcher = errorCatchers.length > 0 ? errorCatchers[0] : undefined;

  if(errorCatcher) {
    log(attachErrorId(err), 'errorCatcher.$emit [to child]', err.message, 'errorCatcher=', errorCatcher);
    errorCatcher.$emit(ERROR_EVENT_NAME, err);
    return true;
  } else {
    return false;
  }
}


/**
 * Look up in parent tree. At each parent component look if:
 *   (1) there exists $el.$refs.errorCatcher (and it is instanceof ErrorCatcher)
 *   (2) $el is instanceof ErrorCatcher
 *
 * If such element is found then return found component.
 * Otherwise return undefined.
 * @param vm
 * @param seen
 */
export function findErrorCatcherInParentTree(vm: Vue | undefined | null, seen = new Set<Vue>()): Vue | undefined {
  if(!vm || seen.has(vm)) {
    return undefined;
  }
  seen.add(vm);

  const errorCatcher = vm.$refs.errorCatcher;
  if(errorCatcher && errorCatcher instanceof Vue) {
    assert(errorCatcher instanceof ErrorCatcher, 'errorCatcher must be of type ErrorCatcher');

    return errorCatcher as Vue;
  } else if(vm instanceof ErrorCatcher) {
    return vm;
  } else {
    return findErrorCatcherInParentTree(vm.$parent, seen);
  }
}

/**
 * Look down in children tree. At each child component look if:
 *   (1) there exists $el.$refs.errorCatcher (and it is instanceof ErrorCatcher)
 *   (2) $el is instanceof ErrorCatcher
 *
 * If such element is found then return found component.
 * Otherwise return undefined.
 * @param vm
 * @param seen
 */
export function findErrorCatchersInChildrenTree(vm: Vue | undefined, seen = new Set<Vue>()): Vue[] {
  if(!vm || seen.has(vm)) {
    return [];
  }
  // seen.add(vm);

  const checkGet = (vm: Vue): Vue | undefined => {
    if(seen.has(vm)) {
      return undefined;
    }
    seen.add(vm);
    const errorCatcher = vm.$refs.errorCatcher;
    // debugger;
    if(errorCatcher && errorCatcher instanceof Vue) {
      assert(errorCatcher instanceof ErrorCatcher, 'errorCatcher must be of type ErrorCatcher');
      return errorCatcher as Vue;
    } else if(vm instanceof ErrorCatcher || vm.$data.isErrorCatcher) {
      return vm;
    } else {
      return undefined
    }
  }

  const get = (vms: Vue[] | undefined): (Vue | undefined)[] => {
    return !vms ? [] : _(vms).flatMap(v => {
      return [checkGet(v)].concat(get(v.$children));
    }).value();
  }

  const list = get([vm]).filter(x => !!x);
  // console.log('got list', list);
  return list as Vue[];
}
