import {BaseService} from '~/lib/api/base/BaseService';
import AuthApiClient from '~/lib/api/clients/AuthApiClient';
import ChildApiClient from '~/lib/api/clients/ChildApiClient';
import CommonApiClient from '~/lib/api/clients/CommonApiClient';
import ShopApiClient from '~/lib/api/clients/ShopApiClient';
import TransactionsApiClient from '~/lib/api/clients/TransactionsApiClient';
import {AuthServiceWithContext} from '~/lib/api/services/AuthService';
import {ChildServiceWithContext} from '~/lib/api/services/ChildService';
import CommonServiceWithContext from '~/lib/api/services/CommonService';
import {ShopServiceWithContext} from '~/lib/api/services/ShopService';
import {TransactionsServiceWithContext} from '~/lib/api/services/TransactionsService';
import {
  AUTH_CHANNEL_NAME,
  CHILD_CHANNEL_NAME,
  COMMON_CHANNEL_NAME,
  SET_SERVICE_CHANNEL_NAME,
  SHOP_CHANNEL_NAME,
  TRANSACTIONS_CHANNEL_NAME,
} from '~/store/constants';
import ErrorBroker from '~/store/ErrorBroker';


/**
 * DO NOT REMOVE
 */
SET_SERVICE_CHANNEL_NAME(AuthServiceWithContext, AUTH_CHANNEL_NAME);
SET_SERVICE_CHANNEL_NAME(ChildServiceWithContext, CHILD_CHANNEL_NAME);
SET_SERVICE_CHANNEL_NAME(ShopServiceWithContext, SHOP_CHANNEL_NAME);
SET_SERVICE_CHANNEL_NAME(TransactionsServiceWithContext, TRANSACTIONS_CHANNEL_NAME);
SET_SERVICE_CHANNEL_NAME(CommonServiceWithContext, COMMON_CHANNEL_NAME);



export class AuthService extends BaseService<AuthApiClient, AuthServiceWithContext> {
  constructor(
    apiClient: AuthApiClient,
    errorBroker: ErrorBroker,
  ) {
    super(apiClient, AuthServiceWithContext, errorBroker);
  }
}

export class ChildService extends BaseService<ChildApiClient, ChildServiceWithContext> {
  constructor(
    apiClient: ChildApiClient,
    errorBroker: ErrorBroker,
  ) {
    super(apiClient, ChildServiceWithContext, errorBroker);
  }
}

export class ShopService extends BaseService<ShopApiClient, ShopServiceWithContext> {
  constructor(
    apiClient: ShopApiClient,
    errorBroker: ErrorBroker,
  ) {
    super(apiClient, ShopServiceWithContext, errorBroker);
  }
}

export class TransactionsService extends BaseService<TransactionsApiClient, TransactionsServiceWithContext> {
  constructor(
    apiClient: TransactionsApiClient,
    errorBroker: ErrorBroker,
  ) {
    super(apiClient, TransactionsServiceWithContext, errorBroker);
  }
}

export class CommonService extends BaseService<CommonApiClient, CommonServiceWithContext> {
  constructor(
    apiClient: CommonApiClient,
    errorBroker: ErrorBroker,
  ) {
    super(apiClient, CommonServiceWithContext, errorBroker);
  }
}

