import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { commonService } from '~/lib/accessors/api';
import { parsePageContent } from '~/lib/page_content';


export const name = 'GlobalStore';

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class GlobalStore extends VuexModule {
  vodalVisible: boolean = false;
  vodalText: string = '';
  vodalHtml: string = '';

  parsedPageContent: ParsedPageContent | null = null;

  @Mutation
  openVodal(text: string): void {
    this.vodalVisible = true;
    this.vodalText = text;
  }

  @Mutation
  openVodalHtml(html: string): void {
    this.vodalVisible = true;
    this.vodalText = '';
    this.vodalHtml = html;
  }

  @Mutation
  closeVodal(): void {
    this.vodalVisible = false;
    this.vodalText = '';
    this.vodalHtml = '';
  }

  @Mutation
  setParsedPageContent(content: ParsedPageContent): void {
    this.parsedPageContent = content;
  }

  @Action
  async refreshPageContent({vmContext}: {vmContext: Vue}) {
    const resp = await commonService
        .withContext(vmContext)
        .getPageContent();

    const parsedPageContent = parsePageContent(resp);

    this.setParsedPageContent(parsedPageContent);
  }

  // @Mutation
  // setVodalVisible(value: boolean): void {
  //   this.vodalVisible = value;
  // }
}
