export const ALL_CHANNEL_NAME          = '*';
export const AUTH_CHANNEL_NAME         = 'auth_service';
export const CHILD_CHANNEL_NAME        = 'child_service';
export const SHOP_CHANNEL_NAME         = 'shop_service';
export const TRANSACTIONS_CHANNEL_NAME = 'transactions_service';
export const COMMON_CHANNEL_NAME       = 'common_service';

export const LAYOUT_CHANNEL_NAME       = 'layout';
export const PAGE_CHANNEL_NAME         = 'page';
export const PAGE_SECTION_CHANNEL_NAME = 'page_section';


/**
 * SERVICE_CHANNEL_NAMES are filled inside index.ts (to prevent circulaer dependencies)
 */
export const SERVICE_CHANNEL_NAMES = new Map<any, string>();

export function SET_SERVICE_CHANNEL_NAME<T extends new (...args: any[]) => any>(clazz: T, value: string) {
  SERVICE_CHANNEL_NAMES.set(clazz, value);
}