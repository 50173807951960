import {Context} from '@nuxt/types';
import {Auth} from '@nuxtjs/auth-next';
import _ from 'lodash';
import Vue from 'vue';


function anonymousUser(): AuthUser {
  return {
    email: 'anonymous@example.com',
    role: 'helper',
    child: {
      id: -1,
      first_name: '',
      last_name: '',
      notes: '',
      photo: '',
      ref: '',
      photo_thumb: '',
      photo_thumb_desktop: '',
      photo_thumb_mobile: '',
    },
    is_profile_complete: true,
    profile_incomplete_elements: [] as any[],
    total_amount: '',
    isLoggedIn: false
  } as AuthUser
}


function $getUserByAuth($auth: Auth): AuthUser {
  const account = $auth.user;
  if (!account) {
    return anonymousUser()
  } else {
    const user = _.cloneDeep(account);
    user.isLoggedIn = true;
    return user as unknown as AuthUser;
  }
}

Vue.prototype.$user = function $getUser(): AuthUser {
  return $getUserByAuth(this.$auth);
}

declare module 'vue/types/vue' {
  interface Vue {
    $user: () => AuthUser
  }
}

declare module '@nuxt/types' {
  interface Context {
    $user: () => AuthUser
  }
}

export default function (context: Context) {
  context.$user = () => $getUserByAuth(context.$auth);
}

