import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { is_running_in_pwa } from "~/lib/util/util";

export const name = "IsPwaStore";

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class IsPwaStore extends VuexModule {
  is_pwa: boolean = false;

  @Mutation
  setIsPwa(is_pwa: boolean): void {
    this.is_pwa = is_pwa;
  }
}

export function start_monitoring_is_pwa(isPwaStore: IsPwaStore) {
  isPwaStore.setIsPwa(is_running_in_pwa());
  const stop_listening = on_is_running_in_pwa_change((is_pwa) => {
    isPwaStore.setIsPwa(is_pwa);
  });
}

function on_is_running_in_pwa_change(callback: (is_pwa: boolean) => void) {
  const m = window.matchMedia("(display-mode: standalone)");

  const listener = (event: MediaQueryListEvent) => {
    console.log("on_is_running_in_pwa_change ->", event.matches);
    callback(event.matches);
  };

  m.addEventListener("change", listener);

  const remove = () => {
    m.removeEventListener("change", listener);
  };

  return remove;
}
