
import Vue, {PropOptions} from 'vue';
import { vueWindowSizeMixin } from 'vue-window-size';
import appConfig from '~/lib/util/appConfig';
import { calcTailwindBreakpoint } from '~/lib/util/window_resize_mixin_for_root';

type MenuItem = {
  id: number,
  text: string,
  icon_src?: string,
  hidden?: boolean,
  action: () => void
};


function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
};


export default Vue.extend({
  name: "PrettyContextMenu",
  mixins: [vueWindowSizeMixin],
  props: {
    position: {
      type: String,
      default: "center"
    } as PropOptions<'center' | 'left' | 'right'>,
    menuItems: {
      type: Array,
      default: () => []
    } as PropOptions<MenuItem[]>,

    // user defined visibility (optional)
    visible: {
      type: Boolean,
      default: undefined
    }
  },
  data() {
    return {
      visible_: false,
      modalBackground: false,
      sliderCloseSeconds: 0.5,
      percHeightHideThreshold: 0.4,
      initialTranslateYPerc: 0.2,
      clickOutsideEnabled: false
    }
  },
  computed: {
    is_visible() {
      // @ts-ignore: TODO
      return this.visible !== undefined ? this.visible : this.visible_;
    },
    user_defined_visibility() {
      const ret = this.visible !== undefined;
      if(ret && appConfig.dev) {
        console.error('PrettyContextMenu user_defined_visibility is unstable. Do not use in production.');
      }
      return ret;
    },
    initialTransform() {
      // return `translateY(${this.initialTranslateYPerc*100}%)`;
      return `translateY(100%)`;
    },
    posClass() {
      return {
        'left': 'pos-left',
        'center': 'pos-center',
        'right': 'pos-right'
      }[this.position];
    },
    touchEventsEnabled() {
      const breakpoint = calcTailwindBreakpoint(this, this.windowWidth);
      return this.$isTouchScreen && breakpoint.has('xs');
    },
    customClickOutside() {
      // We use this only to change 'click' event into 'mousedown' event, so when
      // somebody performs presses mouse button inside modal and then moves mouse
      // outside and releases the button, the modal will NOT close.
      // @ts-ignore: for some reason `this.isTouch` is not recognized by typescript
      const EVENTS = this.$isTouchScreen ? ['touchstart', 'mousedown'] : ['mousedown'];

      return {
        handler: async () => {
          if(! this.clickOutsideEnabled) {
            return;
          }

          if(this.touchEventsEnabled) {
            const slider: HTMLElement = this.$refs.xxx as HTMLElement;
            if(slider) {
              slider.style.transform = `translateY(100%)`;  // move it by 2x its height to the bottom
              // await sleep(400);
            }
          }
          this.close();
        },
        events: EVENTS
      }
    },
  },
  mounted() {
    this.$on('open', async () => {
      if(this.user_defined_visibility) {
        throw new Error('context menu: cannot use open/close events with externally provided "visible" prop');
      }

      this.visible_ = true;

      if(this.touchEventsEnabled) {
        await this.$nextTick();
        this.modalBackground = true;
        await this.initSlider();
      }

      this.clickOutsideEnabled = true;

    });
    this.$on('close', async () => {
      if(this.user_defined_visibility) {
        throw new Error('context menu: cannot use open/close events with externally provided "visible" prop');
      }

      this.clickOutsideEnabled = false;

      if(this.touchEventsEnabled) {
        this.modalBackground = false;
        await sleep(400);
        this.deinitSlider();
      }

      this.visible_ = false;
    });
  },
  methods: {
    close() {
      if(this.user_defined_visibility) {
        this.$emit('update:visible', false);
      } else {
        this.$emit('close');
      }
    },
    deinitSlider() {
      const slider: HTMLElement | undefined = this.$refs.xxx as HTMLElement;

      if(slider) {
        slider.onpointerdown = null;
        slider.onpointerup = null;
        slider.onpointermove = null;
      }
    },
    async initSlider() {
      if(! this.touchEventsEnabled) {
        return;
      }

      const slider: HTMLElement = this.$refs.xxx as HTMLElement;


      // slider.style.transition = `transform 0.4s`;
      // await this.$nextTick();
      slider.style.transform = `translateY(${this.initialTranslateYPerc*100}%)`;
      // await sleep(100);

      if(! slider) {
        throw new Error('no slider');
      }

      let deltaY = 0; // initial point after first touch
      let ZERO = 0;
      let translateY = 0;  // last translateY
      let minTranslateY = 0; // maximum position while moving upwards
      let windowHeight = window.innerHeight;
      let lastDirection = 0;
      let pointerId: number | undefined = undefined;
      let elemHeight = 0;
      let self = this;

      function getZero() {
        let height = getElemHeight();
        return self.initialTranslateYPerc * height;
      }

      function getElemHeight() {
        let elemRect = slider.getBoundingClientRect();
        return Math.abs(elemRect.top - elemRect.bottom);  // height including borders
      }


      function beginSliding(e: PointerEvent) {
        // We do it here, because inside initSlider we get wrong height (element is
        // not fully rendered it seems).
        elemHeight = getElemHeight();
        ZERO = getZero();
        translateY = ZERO;
        minTranslateY = 0;
        pointerId = e.pointerId;
        deltaY = windowHeight - e.clientY + translateY;
        slider.style.transition = `transform 0s`;
        slider.onpointermove = slide;
        slider.setPointerCapture(e.pointerId);
      }

      async function stopSliding(e: PointerEvent) {
        slider.onpointermove = null;
        slider.releasePointerCapture(e.pointerId);
        slider.style.transition = ``;

        // const elemHeight = getElemHeight();
        const hideThreshold = self.percHeightHideThreshold * elemHeight;

        // If element is hidden inside bottom part of the screen by
        // percHeightHideThreshold percent of its height then close it.
        if(translateY > ZERO && Math.abs(translateY - ZERO) > hideThreshold) {
          self.deinitSlider();
          slider.style.transform = `translateY(100%)`;  // move it by 2x its height to the bottom
          // slider.style.transition = `transform 0.4s`;
          // await sleep(400);
          self.close();
        }
        // Otherwise movie it back to its original position.
        else {
          slider.style.transform = `translateY(${ZERO}px)`;
          // slider.style.transition = `transform 0.5s`;
          translateY = 0;
        }

      }

      function slide(e: PointerEvent) {
        lastDirection = e.movementY > 0 ? 1 : -1;
        translateY = e.clientY - windowHeight + deltaY;
        translateY = Math.max(minTranslateY, translateY);
        slider.style.transform = `translateY(${translateY}px)`;
      }

      slider.onpointerdown = beginSliding;
      slider.onpointerup = stopSliding;
    }
  }
})
