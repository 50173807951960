import {BaseServiceWithContext} from '~/lib/api/base/BaseServiceWithContext';
import TransactionsApiClient from '~/lib/api/clients/TransactionsApiClient';
import {GetServiceMethods} from '~/lib/api/services/WrapperTypes';
import {handleErrors} from '~/lib/api/types/errors/handleErrors';
import {FetchPage} from '~/lib/api/types/FetchPage';


export class TransactionsServiceWithContext extends BaseServiceWithContext<TransactionsApiClient>
  implements GetServiceMethods<TransactionsApiClient> {

  @handleErrors()
  async getTransactions(account: Account | null, fetchPage: FetchPage) {
    return this.apiClient.getTransactions(account, fetchPage);
  }

  @handleErrors()
  getTransactionsAggregate() {
    return this.apiClient.getTransactionsAggregate();
  }
}