import { render, staticRenderFns } from "./error.vue?vue&type=template&id=e88d10e0&scoped=true&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"
import style0 from "./error.vue?vue&type=style&index=0&id=e88d10e0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e88d10e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageTitle: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/page-components/PageTitle.vue').default,PageSubTitle: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/page-components/PageSubTitle.vue').default,DebugMessage: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/util/DebugMessage.vue').default,AppLink: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/util/AppLink.vue').default,PageSection: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/PageSection.vue').default,Page: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/Page.vue').default})
