import {Component} from 'nuxt-property-decorator';
import Vue from 'vue';
import {appendErrorId, attachErrorId} from '~/lib/api/types/errors/Errors';
import {RETHROW_IN_VUE_CONTEXT_EVENT_NAME} from '~/plugins/mixin-api-error-handlers';


@Component
export default class RethrowErrorInVueContextComponent extends Vue {
  mounted() {
    (window as any).__MY_LAYOUT_VUE_COMPONENT = this;
    this.$on(RETHROW_IN_VUE_CONTEXT_EVENT_NAME, (err: Error) =>{
      const errorId = attachErrorId(err);
      console.log(appendErrorId('Rethrowing error in Vue context', errorId));
      throw err;
    });
  }

  beforeDestroy() {
    delete (window as any).__MY_LAYOUT_VUE_COMPONENT;
  }
}