import Vue from 'vue'

// let touchstartX = 0
// let touchendX = 0
//
// function checkDirection() {
//   if (touchendX < touchstartX) alert('swiped left!')
//   if (touchendX > touchstartX) alert('swiped right!')
// }
//
// document.addEventListener('touchstart', e => {
//   touchstartX = e.changedTouches[0].screenX
// })
//
// document.addEventListener('touchend', e => {
//   touchendX = e.changedTouches[0].screenX
//   checkDirection()
// })

const HANDLERS_PROPERTY = '__v-swiped'


Vue.use({
  install: vue => {
    vue.directive('swiped', {
      bind(el, binding, vnode) {
        const reset = () => {
          el.dataset.touchstartX = 0
          el.dataset.touchendX = 0
          el.dataset.touchstartY = 0
          el.dataset.touchendY = 0
        };

        reset();

        function compare_emit(x1, x2, emit_event) {
          console.log(`${emit_event}? --> ${x2 < x1}`)
          if (x2 < x1) {
            if(vnode instanceof Vue) {
              vnode.$emit(emit_event)
            } else {
              el.dispatchEvent(new CustomEvent(emit_event))
            }
          }
        }

        el[HANDLERS_PROPERTY] = {};

        el[HANDLERS_PROPERTY].touchstartListener = function(e) {
          el.dataset.touchstartX = e.changedTouches[0].screenX
          el.dataset.touchstartY = e.changedTouches[0].screenY
        }
        el[HANDLERS_PROPERTY].touchendListener = function(e) {
          el.dataset.touchendX = e.changedTouches[0].screenX;
          el.dataset.touchendY = e.changedTouches[0].screenY;

          compare_emit(el.dataset.touchstartX, el.dataset.touchendX, 'swiped-left')
          compare_emit(el.dataset.touchendX, el.dataset.touchstartX, 'swiped-right')
          compare_emit(el.dataset.touchstartY, el.dataset.touchendY, 'swiped-down')
          compare_emit(el.dataset.touchendY, el.dataset.touchstartY, 'swiped-up')

          reset();
        }

        el.addEventListener('touchstart', el[HANDLERS_PROPERTY].touchstartListener);
        el.addEventListener('touchend', el[HANDLERS_PROPERTY].touchendListener);
      },
      unbind(el) {
        el.removeEventListener('touchstart', el[HANDLERS_PROPERTY].touchstartListener)
        el.removeEventListener('touchend', el[HANDLERS_PROPERTY].touchendListener)
      }
    })
  }
})
