import { Module, Mutation, VuexModule } from "vuex-module-decorators";

export const name = "ConnectivityStore";

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class ConnectivityStore extends VuexModule {
  is_connected: boolean = false;
  conn_state: 'connecting' | 'connected' | 'disconnected' | 'error' = 'connected';

  @Mutation
  setIsConnected(is_connected: boolean): void {
    console.log("setting is_connected ->", is_connected);
    this.is_connected = is_connected;
  }

  @Mutation
  setConnState(state: 'connecting' | 'connected' | 'disconnected' | 'error'): void {
    console.log("setting state ->", state);
    this.conn_state = state;
  }
}

export function recheckConnectivity() {
  console.log("rechecking connectivity");
  // @ts-ignore
  window['kipHandleConnection']();
}

/**
 * Modified version of https://stackoverflow.com/a/44766737/1637178 (CC BY-SA 4.0 by
 * https://stackoverflow.com/users/6277151/tony19)
 */
export async function start_monitoring_connectivity(store: ConnectivityStore) {
  // Test this by running the code snippet below and then
  // use the "Offline" checkbox in DevTools Network panel

  let controller: AbortController | undefined = undefined;
  let parent_controller: AbortController = new AbortController();

  await handleConnection();
  window.addEventListener("online", handleConnection);
  window.addEventListener("offline", handleConnection);

  // @ts-ignore
  window['kipHandleConnection'] = handleConnection;

  async function handleConnection() {
    parent_controller.abort();
    parent_controller = new AbortController();

    let parent_controller_ = parent_controller;

    if (navigator.onLine) {
      store.setConnState('connecting');

      // try {
      //   await exponentialBackoff({
      //     test: () => isReachable(getServerUrl(), 20_000).then(function (online) {
      //       if (online) {
      //         // handle online status
      //         console.log("online");
      //         store.setIsConnected(true);
      //         store.setConnState('connected');
      //         return true;
      //       } else {
      //         console.log("no connectivity");
      //         store.setIsConnected(false);
      //         store.setConnState('disconnected');
      //         return false;
      //       }
      //     }),
      //     i: 0,
      //     max_retries: 20,
      //     max_wait_time: 90_000,
      //     signal: parent_controller,
      //   });
      // } catch(err) {
      //   console.error('exponentialBackoff failed:', err);
      //   controller?.abort();
      //   controller = undefined;
      //   store.setIsConnected(false);
      //   store.setConnState('error');
      // }
      async function check(): Promise<boolean> {
        const online = await isReachable(getServerUrl(), 20000);
        if (online) {
          // handle online status
          console.log("online");
          store.setIsConnected(true);
          store.setConnState('connected');
          return true;
        } else {
          console.log("no connectivity");
          store.setIsConnected(false);
          store.setConnState('disconnected');
          return false;
        }
      }

      let retries = 5;
      let success = false;
      while(retries > 0) {
        if(parent_controller_.signal.aborted) {
          console.log('parent controller aborted');
          break;
        }
        if(await check()) {
          success = true;
          break;
        }
        retries -= 1;
        await new Promise(resolve => setTimeout(resolve, 5000));
      }

      if(! success) {
        if(parent_controller_.signal.aborted) {
          console.log('parent controller aborted');
        } else {
          console.error('fatal - cannot connect to server');
          controller?.abort();
          controller = undefined;
          store.setIsConnected(false);
          store.setConnState('error');
        }
      }

    } else {
      // handle offline status
      console.log("offline");
      store.setIsConnected(false);
      store.setConnState('disconnected');
    }
  }

  async function isReachable(url: string, timeout_ms: number): Promise<boolean> {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    try {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();

      const timeoutId = setTimeout(() => controller?.abort(), timeout_ms);

      try {
        const resp = await fetch(url, {
          method: "HEAD",
          mode: "no-cors",
          signal: controller.signal,
        });
        clearTimeout(timeoutId);
        const is_success = resp ? resp.ok || resp.type === "opaque" : false;

        console.log("[conn test result]:", is_success ? "success" : "failure");

        return is_success;
      } catch (err: any) {
        if (err && err["name"] === "AbortError") {
          console.warn("[conn test failure]: request timed out");
        } else {
          console.warn("[conn test failure]:", err);
        }
        return false;
      }
    } catch (err) {
      console.warn("[conn test failure]:", err);
      return false;
    }
  }

  function getServerUrl() {
    // return window.location.origin;
    return 'https://www.kupujipomagaj.pl';
  }
}

function calcExponentialBackoff(i: number, dt: number) {
  const f = (j: number) => j >= 0 ? Math.exp(j/3)*dt : 0
  const ret = f(i)-f(i-1)
  if(ret < dt) {
    return dt
  } else {
    return ret
  }
}

interface RetryCheck {
  test: () => Promise<boolean> | boolean
  max_retries: number
  max_wait_time: number
  i: number

  signal: AbortSignal | undefined
}

async function exponentialBackoff(retry_check: RetryCheck, base_dt=25) {
  let {test, max_retries, max_wait_time, i} = retry_check

  max_retries = max_retries === undefined ? 20 : max_retries;
  max_wait_time = max_wait_time === undefined ? 30000 : max_wait_time;
  i = i === undefined ? 0 : i;

  return new Promise(async (resolve, reject) => {
    console.log("  retryCheck max_retries = %s, max_wait_time = %s, i = %s", max_retries, max_wait_time, i)

    if(max_retries <= 0 || max_wait_time <= 0) {
      console.log('exponentialBackoff timeout or max retries exceeded');
      reject();
    } else {
      let res = test();
      if(res instanceof Promise) {
        try {
          res = await res;
        } catch(err) {
          reject(err);
          return;
        }
      }
      if(! res) {
        const dt = calcExponentialBackoff(i, base_dt)
        const stop_timeout = setTimeout(() => exponentialBackoff({
          test: test,
          max_retries: max_retries-1,
          max_wait_time: max_wait_time-dt,
          i: i+1,
          signal: retry_check.signal
        }), dt);

        function abort() {
          clearTimeout(stop_timeout);
          reject();
        }

        if(retry_check.signal) {
          retry_check.signal.addEventListener('abort', abort);
        }
      } else {
        resolve(undefined);
      }
    }
  });

}
