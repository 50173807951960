import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=6151e616&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=ts&"
export * from "./Page.vue?vue&type=script&lang=ts&"
import style0 from "./Page.vue?vue&type=style&index=0&id=6151e616&prod&scoped=true&lang=css&"
import style1 from "./Page.vue?vue&type=style&index=1&id=6151e616&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6151e616",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Success: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/Notifications/Success.vue').default,Warn: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/Notifications/Warn.vue').default,ErrorCatcher: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/ErrorCatcher.vue').default})
