import {BaseServiceWithContext} from '~/lib/api/base/BaseServiceWithContext';
import {ErrorHandlers} from '~/lib/api/types/types';
import {waitTimeout} from '~/lib/util/util';
import {CHANNEL_SEPARATOR} from '~/store/ErrorBroker';


export type HandleErrorsOptions = {
  errorHandlers?: ErrorHandlers,
  onBeforeThrowError?: () => void,
  map?: (value: any) => any,
};

export function getErrorChannel(className: string, methodName: string) {
  return `${className}${CHANNEL_SEPARATOR}${methodName}`;
}

// https://stackoverflow.com/a/56189998/1637178
export const handleErrors = (config: HandleErrorsOptions = {}) => {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (this: BaseServiceWithContext<any>, ...args: any[]) {
      if(this.vm) {
        this.vm.$clearAllChildErrorCatchers();
      }

      // const errorChannel = getErrorChannel(target.constructor.name, propertyKey);
      const errorChannel = getErrorChannel(target.getBaseChannelName(), propertyKey);
      if(this.errorBroker) {
        this.errorBroker.clearErrorsFromChannel(errorChannel);
      }

      return this.exec({
        callback: async () => {
          return originalMethod.apply(this, args);
        },
        map: config.map,
        errorHandlers: config.errorHandlers || {},
        errorChannel
      });
    };
  };
};

export function ErrorChannel<T extends { new (...args: any[]): {} }>(constructor: T, baseErrorChannel: string) {
  return class extends constructor {
    _baseErrorChannel = baseErrorChannel;
  };
}
