import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StatusLabelsWithDescription } from '~/lib/types/TransactionView.js';
// import {store} from "~/store/index";


export const name = 'TransactionInfoPopupStore';

@Module({
  name: name,
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class TransactionInfoPopupStore extends VuexModule {
  vodalVisible: boolean = false;

  label: StatusLabelsWithDescription | '' = '';
  labelCssClass: string = '';

  @Mutation
  openVodal(label: StatusLabelsWithDescription, labelCssClass: string): void {
    this.vodalVisible = true;
    this.label = label;
    this.labelCssClass = labelCssClass;
  }

  @Mutation
  closeVodal(): void {
    this.vodalVisible = false;
    this.label = '';
    this.labelCssClass = '';
  }
}
