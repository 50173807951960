
import Vue, {PropOptions} from 'vue';
import Success from '~/components/Notifications/Success.vue';
import Warn from '~/components/Notifications/Warn.vue';
import {appendErrorId, attachErrorId, BackendUserError, BaseUIError, ClientTimeoutError, NetworkError, UIError, WarnError} from '~/lib/api/types/errors/Errors';
import {genUniqueId} from '~/lib/util/util';
import {CLEANUP_EVENT_NAME, ERROR_EVENT_NAME, WARN_EVENT_NAME} from '~/plugins/mixin-api-error-handlers';
import { recheckConnectivity } from '~/store/ConnectivityStore';

export default Vue.extend({
  components: {Success, Warn},
  data() {
    return {
      isErrorCatcher: true
    }
  },
  props: {
    warningMessage: {
      type: String,
      default: undefined,
      required: false
    } as PropOptions<string>
  },
  created() {
    this.$on(ERROR_EVENT_NAME, (e: Error) => this.handleError(e));
    this.$on(WARN_EVENT_NAME, (warn: string) => this.handleWarn(warn));
    this.$on(CLEANUP_EVENT_NAME, () => this.cleanup());
    if(this.warningMessage) {
      this.handleWarn(this.warningMessage);
    }
  },
  methods: {
    handleError(e: Error) {
      try {
        const errorId = attachErrorId(e);
        console.log(appendErrorId(`<ErrorCatcher/> :: got new error with type=${e.constructor.name} message="${e.message}"`, errorId))
        console.log(appendErrorId(`<ErrorCatcher/> :: ${e instanceof NetworkError}`, errorId))

        const console_warn = (m: any) => console.warn('<ErrorCatcher/>   ->', appendErrorId(m, errorId), e);

        if (e instanceof BackendUserError) {
          console_warn(e.user_error);
          this.notifications.warn = e.user_error;

        } else if (e instanceof ClientTimeoutError) {
          console_warn(e.err.message);
          this.notifications.warn = 'Przekroczono limit czasu połączenia (sprawdź stabilność łącza internetowego)';

        } else if (e instanceof BaseUIError) {

          if(e instanceof WarnError) {
            console_warn(e.message);
            this.notifications.warn = (e.message || appendErrorId('Błąd aplikacji (kod 1)', errorId));

          } else if(e instanceof UIError) {
            console_warn(e.message);
            this.notifications.warn = (e.message || appendErrorId('Błąd', errorId));

          } else if(e instanceof NetworkError) {
            console_warn(e.message);
            this.notifications.warn = 'Błąd połączenia';
            recheckConnectivity();

          } else {
            console.error('<ErrorCatcher/> ::', e);
            this.notifications.warn = appendErrorId('Błąd aplikacji (kod 2)', e.errorId);
          }
        } else {
          const errorId = genUniqueId();
          if(e instanceof DOMException) {
            console.warn('DOMException: cannot append errorId')
          } else {
            e.message = appendErrorId(e.message, errorId);
          }
          console_warn(e.message);
          this.notifications.warn = appendErrorId('Błąd aplikacji (kod 3)', errorId);

        }
      } catch(ex) {
        console.error('<ErrorCatcher/> ::handleError FATAL', ex);
        console.error('<ErrorCatcher/> ::handleError Original error', e);
        throw ex;
      }
    },
    handleWarn(msg: string) {
      console.log(`<ErrorCatcher/> ::handlerWarn "${msg}"`)
      this.notifications.warn = (msg);
    },
    cleanup() {
      console.log(`<ErrorCatcher/> ::cleanup`);
      this.notifications.warn = '';
    },
  },
  computed: {
    warnBlock(): string[] {
      return this.notifications.warn && this.notifications.warn.length > 0 ?
          this.notifications.warn.split(';').map(s => s.trim()) : []
    }
  },
  watch: {
    warningMessage(msg) {
      if(msg) {
        this.handleWarn(msg);
      }
    }
  }
})
