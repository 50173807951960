import { EventEmitter2 } from 'eventemitter2';
import _ from 'lodash'
import Vue from 'vue';
import {vueWindowSizeMixin} from 'vue-window-size';
import { TailwindBreakpoint } from '~/plugins/kip-tailwind-theme.js';

class WindowSizeEvents extends EventEmitter2 {
}

const _events = new WindowSizeEvents();

type ResizeEvent = {
  windowWidth: number,
  windowHeight: undefined,
  tailwindBreakpoint: Set<TailwindBreakpoint>
} | {
  windowWidth: undefined,
  windowHeight: number,
  tailwindBreakpoint: undefined
}

export function calcTailwindBreakpoint(vm: Vue, windowWidth: number): Set<TailwindBreakpoint> {
  const entries = _(vm.$tailwindTheme.breakpoints)
    .entries()
    .orderBy(e => e[1], 'asc')
    .value();

  const sizes = new Set<TailwindBreakpoint>();
  for(const [size, width] of entries) {
    if(windowWidth <= width) {
      // @ts-ignore
      sizes.add(size);
      // return size as TailwindBreakpoint;
    }
  }
  if(sizes.size === 0) {
    console.warn(`Could not determine tailwind breakpoint for window width: ${windowWidth}`);
  }
  return sizes;
}

export const windowResizeMixinForRoot = Vue.extend({
  mixins: [vueWindowSizeMixin],
  beforeDestroy() {
    _events.removeAllListeners('resize');
  },
  data() {
    return {
    }
  },
  computed: {
    tailwindBreakpoint() {
      return calcTailwindBreakpoint(this, this.windowWidth);
    }
  },
  methods: {

  },
  mounted() {
  },
  watch: {
    windowWidth(windowWidth: number) {
      const evt: ResizeEvent = {windowWidth, windowHeight: undefined, tailwindBreakpoint: this.tailwindBreakpoint};
      _events.emit('resize', evt);
    },
    windowHeight(windowHeight: number) {
      const evt: ResizeEvent = {windowWidth: undefined, windowHeight, tailwindBreakpoint: undefined};
      _events.emit('resize', evt);
    }
    // windowWidth(windowWidth: number) {
    //   const breakpoint = this.calcTailwindBreakpoint(windowWidth);
    //   const evt: ResizeEvent = {windowWidth, windowHeight: undefined, tailwindBreakpoint: breakpoint};
    //   _events.emit('resize', evt);
    // },
    // windowHeight(windowHeight: number) {
    //   const evt: ResizeEvent = {windowWidth: undefined, windowHeight, tailwindBreakpoint: undefined};
    //   _events.emit('resize', evt);
    // }
  }
});

type ResizeCallback = ((evt: ResizeEvent) => void) | (() => void);


export const windowResizeMixinForChild = Vue.extend({
  data() {
    return {
      resizeCallbacks: [] as ResizeCallback[]
    }
  },
  methods: {
    $onWindowResize(callback: ResizeCallback) {
      const callback_ = _.debounce(callback, 100);
      this.resizeCallbacks.push(callback_);

      // @ts-ignore
      _events.on('resize', callback_);

      return () => {
        // @ts-ignore
        _events.off('resize', callback_);
        this.resizeCallbacks = this.resizeCallbacks.filter(cb => cb !== callback_);
      }
    }
  },
  mounted() {

  },
  beforeDestroy() {
    for(const callback of this.resizeCallbacks) {
      _events.off('resize', callback);
    }
  },
});
