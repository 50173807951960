
// NuxtLink wrapper compatible with PWA used to prevent opening browser window with
// target=_blank while in PWA context.

import { isPwaStore } from '~/lib/accessors/store-accessor';

export default {
  name: 'AppLink',
  props: {
    to: {
      required: true,
    },
    target: {
      type: String
    },
    title: {
      type: String,
    },
  },
  computed: {
    is_pwa() { return isPwaStore.is_pwa; },
    target_() {
      // We set is_pwa variable at the top level of this function so vuejs tracks it
      // as a target_ computer property dependency.
      const is_pwa = this.is_pwa;

      if(this.target === '_blank') {
        if(is_pwa) {
          return '_self'
        } else {
          return '_blank'
        }
      } else {
        return this.target;
      }
    },
  }
}
