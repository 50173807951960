
export default {
  props: ['name', 'page'],
  data() {
    return {
      title: null,
      page1: null,
    }
  },
  async created() {
    const page = this.page || await this.$content(this.name).fetch()
    this.page1 = page
    this.title = page.title
  }
}
