import Vue from 'vue';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {childService} from '~/lib/accessors/api';


@Module({
  name: 'FilledDocumentsStore',
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class FilledDocumentsStore extends VuexModule {
  childDocumentsStatus: ChildDocumentsStatus | null = null;

  @Action
  async refreshChildDocumentsStatus({vmContext}: {vmContext: Vue}) {
    const resp = await childService
        .withContext(vmContext)
        .getChildDocumentsStatus()

    this.setChildDocumentsStatus(resp.status);
  }

  @Mutation
  setChildDocumentsStatus(status: ChildDocumentsStatus) {
    this.childDocumentsStatus = status;
  }
}