import {EventEmitter2} from 'eventemitter2';
import _ from 'lodash';
import Vue from 'vue';
import {appendErrorId, attachErrorId, WithErrorBroker} from '~/lib/api/types/errors/Errors';
import {emitErrorToChildErrorCatcher} from '~/lib/error-handling/finder_and_emitters';
import {assert} from '~/lib/util/util';
import {PAGE_CHANNEL_NAME} from '~/store/constants';


export const CHANNEL_SEPARATOR = ':';

const getWildCard = (baseChannel: string) => `${baseChannel}${CHANNEL_SEPARATOR}*`;

export default class ErrorBroker extends EventEmitter2 {
  static readonly INSTANCE: ErrorBroker = new ErrorBroker();

  // private _altChannels = [PAGE_SECTION_CHANNEL_NAME, PAGE_CHANNEL_NAME, LAYOUT_CHANNEL_NAME];
  private _altChannels = [PAGE_CHANNEL_NAME];


  constructor() {
    super({
      ignoreErrors: false,
      verboseMemoryLeak: true,
      newListener: true,
      removeListener: true,
      delimiter: CHANNEL_SEPARATOR,
      wildcard: true,
    });
  }

  clearErrorsFromChannel(errorChannel: string) {
    console.debug(`ErrorBroker::clearErrorsFromChannel errorChannel=${errorChannel}`)
  }

  static CatchError(err: Error, errorCatcherParent?: Vue) {
    const errorId = attachErrorId(err);

    console.debug('errorCatcherParent', errorCatcherParent)
    if(errorCatcherParent) {
      console.debug('try emit to errorCatcherParent')
      if( emitErrorToChildErrorCatcher(err, errorCatcherParent)) {
        console.debug('emitted errorCatcherParent')
        return;
      }
    }

    if ((err as WithErrorBroker).errorChannel) {
      const _err = err as WithErrorBroker;
      // assert(_err.errorBroker instanceof ErrorBroker, '_err.errorBroker must be instanceof ErrorBroker but: ' + _err.errorBroker);
      assert(_err.errorChannel, '_err.errorChannel must be provided');
      assert(_.isString(_err.errorChannel), '_err.errorChannel must be string, but got: ' + _err.errorChannel);
      ErrorBroker.INSTANCE.emitError(_err.errorChannel!, err);
    } else {
      console.warn(appendErrorId('ErrorBroker::CatchError failed to redirect error, no errorChannel' +
        ' attached to error, rethrowing:', errorId), err);
      throw err;
    }
  }

  private emitError(errorChannel: string, err: Error) {
    const errorId = attachErrorId(err);
    const [baseChannel, nestedChannel] = errorChannel.split(CHANNEL_SEPARATOR);

    const wildCardChannel = getWildCard(baseChannel);

    const tryChannels = [errorChannel].concat(this._altChannels);

    let emitToChannel;
    for(const channel of tryChannels) {
      if (this.hasListeners(channel)) {
        emitToChannel = channel;
        console.debug(appendErrorId(`Switched channels from "${errorChannel}" (no listeners available) into "${emitToChannel}"`, errorId))
        break;
      }
    }

    if(!emitToChannel) {
      console.warn(appendErrorId(`No targets for channels "${tryChannels.join(', ')}", rethrowing error`, errorId), err);
      throw err;
    }

    this._emit(emitToChannel, err);
  }

  private _emit(channelName: string, err: Error) {
    console.debug(appendErrorId(`ErrorBroker::emit channelName=${channelName}`, (err as any).errorId))
    this.emit(channelName, err);
  }
}
