let counter = 0;
const max = 100;
export function picsum(width: number, height: number, random?: boolean): string {
  let postfix = '';
  if(random) {
    counter++;
    if(counter > max) {
      counter = 0;
    }
    postfix = `?random=${counter}`;
  }
  return `https://picsum.photos/${width}/${height}${postfix}`;
}
