import _ from 'lodash'
import Vue from 'vue';
import {vueWindowSizeMixin} from 'vue-window-size';
import {TailwindBreakpoint} from '~/plugins/kip-tailwind-theme';
import { calcTailwindBreakpoint } from './window_resize_mixin_for_root';


export default Vue.extend({
  mixins: [vueWindowSizeMixin],
  data() {
    return {
      windowSize: new Set<TailwindBreakpoint>()
    }
  },
  methods: {
    calcWindowSize(windowWidth: number) {
      this.windowSize = calcTailwindBreakpoint(this, windowWidth);
    }
  },
  mounted() {
    this.calcWindowSize(window.outerWidth)
  },
  watch: {
    windowWidth(windowWidth: number) {
      this.calcWindowSize(windowWidth)
    }
  }

})

