// import { Middleware } from '@nuxt/types'
// import {initializeAxios} from "~/utils/axios";
//
// const myMiddleware: Middleware = (context) => {
//   console.log('initializeAxios with')
//   initializeAxios(context.$axios)
// }
//
// export default myMiddleware

import {Context} from '@nuxt/types';
import {AppConfig} from '~/etc/env';
import AuthApiClient from '~/lib/api/clients/AuthApiClient';
import ChildApiClient from '~/lib/api/clients/ChildApiClient';
import CommonApiClient from '~/lib/api/clients/CommonApiClient';
import ShopApiClient from '~/lib/api/clients/ShopApiClient';
import TransactionsApiClient from '~/lib/api/clients/TransactionsApiClient';
import {AuthService, ChildService, CommonService, ShopService, TransactionsService} from '~/lib/api/services';
import ErrorBroker from '~/store/ErrorBroker';

// IMPORTANT
// =========
// Note that you cannot reference these variables outside of vue context e.g. in top-level scope of
// a component file or in asyncData functions, because at this stage they are either not
// initialilzed (initializeApi was not yet called) or for some reason compiler breaks with errors
// like this:
//
//     Module not found: Error: Can't resolve '~/lib/accessors/api.js' in '.../kip-frontend/pages/podopieczny'
//
// TODO: below is not the best way (you can see it could be problematic because it depends on when
// you perform imports of below variables).

let childService: ChildService
let authService: AuthService
let transactionsService: TransactionsService
let commonService: CommonService
let shopService: ShopService

let childApiClient: ChildApiClient
let authApiClient: AuthApiClient
let transactionsApiClient: TransactionsApiClient
let commonApiClient: CommonApiClient
let shopApiClient: ShopApiClient

export default function initializeApi({$axios, $config, $auth}: Context) {
  const appConfig = $config['appConfig'] as AppConfig;

  // const errorBroker = new ErrorBroker();
  const errorBroker = ErrorBroker.INSTANCE;

  authApiClient = new AuthApiClient($axios, appConfig);
  childApiClient = new ChildApiClient($axios, appConfig);
  transactionsApiClient = new TransactionsApiClient($axios, appConfig);
  commonApiClient = new CommonApiClient($axios, appConfig);
  shopApiClient = new ShopApiClient($axios, appConfig);

  childService = new ChildService(childApiClient, errorBroker);
  authService = new AuthService(authApiClient, errorBroker);
  transactionsService = new TransactionsService(transactionsApiClient, errorBroker);
  commonService = new CommonService(commonApiClient, errorBroker);
  shopService = new ShopService(shopApiClient, errorBroker);
}

export {
  childService,
  authService,
  transactionsService,
  commonService,
  shopService,

  authApiClient,
  childApiClient,
  transactionsApiClient,
  commonApiClient,
  shopApiClient,
};
