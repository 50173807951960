import {HTTPResponse} from '@nuxtjs/auth-next/dist';
import {BaseServiceWithContext} from '~/lib/api/base/BaseServiceWithContext';
import AuthApiClient from '~/lib/api/clients/AuthApiClient';
import {GetServiceMethods, SimpleTokenStatus} from '~/lib/api/services/WrapperTypes';
import {ErrorResponse} from '~/lib/api/types/errors/ErrorResponse';
import {handleErrors} from '~/lib/api/types/errors/handleErrors';
import {UserCreated} from '~/lib/api/types/responses/UserCreated';
import ChildView from '~/lib/types/ChildView';
import {handleDjangoBadRequest} from '~/lib/util/api_utils';
import {assert} from '~/lib/util/util';


interface LoginRequest {
  username: string
  password: string
}


const LOGIN_FIELD_LABELS                                                  = {
  username: 'Nazwa użytkownika',
  password: 'Hasło',
};
const RESET_PASSWORD_FIELD_LABELS                                         = {
  new_password: 'Nowe hasło',
  old_password: 'Stare hasło',
};
const REGISTER_PARENT_LABELS: Record<keyof RegisterParentRequest, string> = {
  childBirthYear: 'Data urodzenia podopiecznego',
  childFullName: 'Imię i nazwisko',
  childNote: 'Opis podopiecznego',
  childPhoto: 'Zdjęcie podopiecznego',
  email: 'Adres email',
  phoneNumber: 'Numer telefonu',
  password: 'Hasło',
}


export class AuthServiceWithContext extends BaseServiceWithContext<AuthApiClient>
  implements GetServiceMethods<AuthApiClient, 'getAccount' | 'login'> {

  @handleErrors()
  async confirmParentEmail(confirmEmailToken: string): Promise<SimpleTokenStatus> {
    const account = await this.apiClient.confirmParentEmail(confirmEmailToken)

    if (account instanceof ErrorResponse) {
      return 'error'
    } else {
      return account.success ? 'success' : 'expired'
    }
  }

  @handleErrors({
    errorHandlers: {
      '401': e => 'Błędna nazwa użytkownika lub hasło',
      '400': e => handleDjangoBadRequest(e, LOGIN_FIELD_LABELS),
    },
  })
  async login(login: LoginRequest): Promise<HTTPResponse | void> {
    assert(this.vm, 'AuthService::login: must be in vm context, but vm is undefined ($auth not available)');
    return this.vm!.$auth.login({data: login})
  }

  @handleErrors({
    errorHandlers: {
      '400': e => handleDjangoBadRequest(e, REGISTER_PARENT_LABELS),
    },
  })
  registerParent(r: RegisterParentRequest) {
    return this.apiClient.registerParent(r) as Promise<UserCreated>;
  }

  @handleErrors({
    errorHandlers: {
      '400': e => handleDjangoBadRequest(e, REGISTER_PARENT_LABELS),
    },
  })
  registerBasic(email: string, password: string, child: ChildView) {
    return this.apiClient.registerBasic(email, password, child) as Promise<UserCreated>;
  }

  @handleErrors()
  async activate(confirmEmailToken: string): Promise<SimpleTokenStatus> {
    const account = await this.apiClient.activate(confirmEmailToken)

    if (account instanceof ErrorResponse) {
      return 'error'
    } else {
      return account.success ? 'success' : 'expired'
    }
  }

  @handleErrors({
    errorHandlers: {
      '400': e => handleDjangoBadRequest(e, RESET_PASSWORD_FIELD_LABELS, true),
    },
  })
  changePassword(oldPassword: string, newPassword: string) {
    return this.apiClient.changePassword(oldPassword, newPassword)
  }

  isDuplicateChildName(args: any): any {
  }

  @handleErrors()
  resetPassword(email: string) {
    return this.apiClient.resetPassword(email)
  }

  @handleErrors({
    errorHandlers: {
      '400': e => 'Token wygasł. Spróbuj jeszcze raz lub skontaktuj się z nami.',
    },
  })
  resetPasswordConfirm(data: any) {
    return this.apiClient.resetPasswordConfirm(data)
  }
}
