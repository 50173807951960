const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')
const forms = require('@tailwindcss/forms')

const containerBreakpoint = '1160px';

module.exports = {
  purge: [
    './**/*.vue',
    './content/**/*.md',
  ],
  theme: {
    screens: {
      // do not remove (dependency of kip-tailwind-theme
      '2xl': {'min': '1536px'},
      'xl': {'min': '1280px', 'max': '1535px'},
      'lg': {'min': '1024px', 'max': '1279px'},
      'md': {'min': '768px', 'max': '1023px'},
      'sm': {'min': '640px', 'max': '767px'},
      'xs': {'max': '639px'},
      'xxs': {'max': '420px'},
      'desktop': {'min': '640px'},
      'mobile': {'max': '639px'},
      // 'xl': {'max': '1535px'},
      // 'lg': {'max': '1279px'},
      // 'md': {'max': '1023px'},
      // 'xs': {'max': '767px'},
      // 'xs': {'max': '639px'},
      // 'sm': '640px',
      // 'md': '768px',
      // 'lg': '1024px',
      // 'xl': '1280px',
      // '2xl': '1536px',
    },
    extend: {
      variants: {
        display: ['responsive'],
      },
      colors: {
        'kip-violet': '#6369D1',
        'kip-green': '#66D7D1',
        'kip-yellow': '#FFD555',
        'kip-white': '#FFFFFF',
        'kip-lighter-grey': '#F2F3FA',
        'kip-light-grey': '#E4E4E4',
        'kip-grey': '#909090',
        'kip-dark-grey': '#1D1F43',
        "kip-line": "#E4E6F5",
        "kip-dark-violet": "#3B4098",
        "kip-red": "#EE3B5B",
      },
      fontFamily: {
        'kip-heading': ['Mulish', 'sans-serif'],
        'kip-text': ['Montserrat', 'sans-serif'],
        'sans': ['Montserrat', 'sans-serif'],
      },
      fontSize: {
        'kip-h1': ['90px', '1em'],
        'kip-h2': ['48px', '1.2em'],
        'kip-h3': ['32px', '1.4em'],
        'kip-h4': ['24px', '1.6em'],
        'kip-h4.5': ['20px', '1.6em'],
        'kip-h5': ['16px', '1.6em'],
        'kip-h6': '16px',
        'kip-32px': '32px',
        'kip-20px': '20px',
        'kip-16px': '16px',

        'kip-smallest': '12px',
        'kip-smaller': '14px',
        'kip-text': '16px',


        '30': '30px',
        '32': '32px',
        '24': '24px',
        '20': '20px',
        '16': '16px',
        '14': '14px',
      },
      width: {
        '1/10': '10%',
        '2/10': '20%',
        '3/10': '30%',
        '4/10': '40%',
        '5/10': '50%',
        '6/10': '60%',
        '7/10': '70%',
        '8/10': '80%',
        '9/10': '90%',
        'container-breakpoint': containerBreakpoint,
      },
      maxHeight: {
        '1/10': '10%',
        '2/10': '20%',
        '3/10': '30%',
        '4/10': '40%',
        '5/10': '50%',
        '6/10': '60%',
        '7/10': '70%',
        '8/10': '80%',
        '9/10': '90%',
        '1/10vh': '10vh',
        '2/10vh': '20vh',
        '3/10vh': '30vh',
        '4/10vh': '40vh',
        '5/10vh': '50vh',
        '6/10vh': '60vh',
        '7/10vh': '70vh',
        '8/10vh': '80vh',
        '9/10vh': '90vh',
      },
      minHeight: {
        '1/10': '10%',
        '2/10': '20%',
        '3/10': '30%',
        '4/10': '40%',
        '5/10': '50%',
        '6/10': '60%',
        '7/10': '70%',
        '8/10': '80%',
        '9/10': '90%',
        '1/10vh': '10vh',
        '2/10vh': '20vh',
        '3/10vh': '30vh',
        '4/10vh': '40vh',
        '5/10vh': '50vh',
        '6/10vh': '60vh',
        '7/10vh': '70vh',
        '8/10vh': '80vh',
        '9/10vh': '90vh',
      },
      // Override default container class of tailwind with its breakpoints. With below configuration
      // there will be only two breakpoints (it doesnt inherit from default breakpoints of
      // tailwind).
      container: {
        center: true,
        screens: {
          'xl': containerBreakpoint,
          '2xl': containerBreakpoint,
        }
      },
    }
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
  ],
};
